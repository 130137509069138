@use '../../styles/transitions' as m;
@use '../../styles/variables' as v;

.rainbowInputContainer {
    display: flex;
    padding: 2px;
    @include m.animateBorderColor();
    min-width: 100%;
}

.rainbowInput {
    height: 100%;
}

.rainbowInput,
.rainbowInputContainer {
    width: 100%;
    min-width: 100%;
    background-color: v.$documentBackgroundColor !important;
    border-radius: 15px;

    div {
        display: flex;
        width: 100%;
        min-width: 100%;
        border: none !important;

    }
}

.uploadedFilesContainer {
    display: flex;
    justify-content: center;
    column-gap: 8px;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 16px;
}