@use '../../styles/variables';

.layout {
    display: flex;
    width: 100%;
    background: variables.$documentBackgroundColor;

    .sidebar {
        min-width: 266px;
        height: calc(100vh - 60px);
        background: variables.$headerBarColor;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        .contents {
            margin-top: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .menuItems {
                button {
                    all: unset;
                    cursor: pointer;

                    span {
                        display: flex;

                        span {
                            line-height: 25px;
                        }
                    }
                }

                display: flex;
                flex-direction: column;
                row-gap: 20px;

            }
        }
    }
}