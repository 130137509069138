@use '../../styles/variables' as v;
@use '../../styles/animations' as a;

.snackbar {
    color: white;
    min-width: 375px;
    max-width: 375px;
    border-radius: 15px;

    .close {
        width: 100%;
        padding: 5px;
        text-align: right;
        // position: absolute;

        svg {
            max-width: 20px;
            max-height: 20px;
            fill: white;
            stroke: brown;
        }

        cursor: pointer;
    }

    .callForHelp {
        color: white;
        padding: 20px;
        font-style: italic;
    }

    .messages {
        position: relative;
        display: flex;
        background-color: v.$documentBackgroundColor !important;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        border-radius: 15px;



        .iconPanel {
            flex: 0 0 50px;
            height: 50px;
            width: 50px;
            background-size: cover;

            &.info {
                background-image: url('../../assets/svg/info.svg');

            }

            &.error {
                background-image: url('../../assets/svg/error.svg');
            }

            &.warning {
                background-image: url('../../assets/svg/warning.svg');
            }

            &.success {
                background-image: url('../../assets/svg/success.svg');
            }
        }

        .message {
            flex: 1 1 auto;
            padding: 0 20px;
            font-size: 1rem;
        }

        &.error {
            @include a.pulseBoxShadow(rgba(139, 0, 0, 0.5), rgba(139, 0, 0, 0));
            box-shadow: rgba(139, 0, 0, 0.5) 0px 0px 0px 2px;
        }

        &.warning {
            // @include a.pulseBoxShadow(rgb(236, 173, 2, 0.5), rgb(236, 173, 2, 0));
            box-shadow: rgb(236, 173, 2, 0.5) 0px 0px 0px 1px;
        }

        &.success {
            // @include a.pulseBoxShadow(rgb(41, 236, 2, 0.5), rgb(41, 236, 2, 0));
            box-shadow: rgb(41, 236, 2, 0.5) 0px 0px 0px 1px;
        }

        &.info {
            // @include a.pulseBoxShadow(rgb(2, 170, 236, 0.5), rgb(2, 170, 236, 0));
            box-shadow: rgb(2, 170, 236, 0.5) 0px 0px 0px 1px;
        }
    }

    .messages+.messages {
        bottom: 10px;
        left: 5px;
    }
}