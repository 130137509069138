@use './variables.scss';


.backdrop {
    animation-delay: 50ms;
    animation: slideUp 0.2s ease-in-out forwards;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 100px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    top: 0;
    z-index: 1;


    .buttonContent {
        animation-delay: 100ms;
        animation: slideUp 0.5s ease-in-out forwards;
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .actionButton {
            width: 300px;

            &:Hover {
                button {
                    animation: bounce 0.1s ease-out forwards;
                    box-shadow: inset 0px 2px 3px 5px #333435;
                }
            }

            button {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 48px;

                span:nth-child(1) {
                    display: inline-block;
                    width: 40px;
                    height: 28px;

                    svg {
                        height: 28px;
                    }
                }

                span:nth-child(2) {
                    display: inline-block;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    width: 200px;
                    text-align: left;
                }


                width: 100%;
            }
        }
    }
}


@keyframes slideUp {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

@keyframes bounce {
    from {
        scale: 1;
    }

    50% {
        scale: .9;
    }

    to {
        scale: 1;
    }
}