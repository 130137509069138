@use '../../styles/variables';

.primary,
.secondary {
    display: inline-block;
    margin-left: 5px;
    font-weight: 400;
    line-height: 20px;
    text-align: right;
    letter-spacing: 0.25px;
    font-size: 0.75rem;
}

.primary {
    color: variables.$accent;
    fill: variables.$accent;
}

.secondary {
    color: variables.$secondary;
    fill: variables.$secondary;
}