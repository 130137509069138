@use './variables.scss';
@use './mixins.scss';

.notchedOutline {
    border-color: white;
}


.Mui-focused {
    border-color: white !important;
}


.title {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: #FFFFFF;
}


.instruction {
    color: #FFFFFF;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.5;
    letter-spacing: 0.15px;
}

.gradingCompany {
    font-weight: 400;
    font-size: 24px;
    line-height: 16px;
    letter-spacing: 0.18px;
    row-gap: 10px;

    @include mixins.maxWidth600() {
        font-size: 24px;
        line-height: 16px;
    }
}