@use "./variables.scss";
@use "./mixins.scss";

ul {
  li {
    list-style-image: url('../assets/svg/ul.svg');
  }
}

.pictureUploadContainer {
  display: flex;
  column-gap: 24px;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 8px;

  @media screen and (max-width: 800px) {
    justify-content: center;
  }

  .uploadTitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #b4b4b4;

    @media screen and (max-width: 800px) {
      max-width: 400px;
      margin-bottom: 16px;

    }

  }

  .preview {
    width: 495px;
    height: 348px;

    background: #ffffff;
    border: 1px solid #c5c5c5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }



  .fileSelectionContainer {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    row-gap: 14px;
    column-gap: 8px;
    max-width: 500px;
    width: 100%;

    @media screen and (max-width: 800px) {
      max-width: 400px; // keep all the icons 4 to a row.

    }

    .iconContainer {
      display: flex;
      height: 38px;
      column-gap: 15px;
      align-items: center;
    }
  }
}