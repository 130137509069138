.myListings {
    padding: 25px 0 0 125px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    //width: 60vw;
    //row-gap: 20px;

    .loadMoreButtonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        min-width: 100%;

        rainbow-button {
            width: 100%;
        }
    }

    user-asset {
        border-bottom: 1px solid #ffffff40;
    }

    .binIcon {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        background-color: blue !important;
    }

    @media screen and (max-width: 800px) {
        padding: 25px 0 0 25px;
    }
}