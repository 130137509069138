.headerAndContent {
    .content {
        background-color: #161D22;
        padding: 15px;

        p {
            overflow-wrap: break-word;
            word-break: break-all;
        }
    }

    .title {
        display: inline-block;
        margin-top: 15px;
        margin-bottom: 5px;


    }
}