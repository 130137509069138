@use "variables.scss";
@use "mixins.scss";


//!#######################
// These settings ensure that the image retains its aspect ratio, 
// Resize the container if you wish to change the size of the image
// Test landscape immages and portrait images alike. 
// right now the onus is on the user to ensure the image lines up with the border
// at least until we put in some kind of cropping mechanism
//!#######################


.selectedCardsPreview {
    //!#######################
    // these settings ensure that the image retains its aspect ratio, 
    // resize the container if you wish to change the size of the image
    //!#######################
    background: linear-gradient(194.15deg, #F48265 -3.07%, #FFF5B8 22.2%, #B8DCA2 41.55%, #B8E3EE 63.59%, #7F99D0 89.94%);
    box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.15);
    border-radius: 6px;
    font-size: 12px;
    padding: 4px;
    min-height: 383px;
    min-width: 257px;
    max-width: 257px;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;

    .contents {
        background-color: variables.$documentBackgroundColor;

        .thumbnail {
            //!#######################
            // these settings ensure that the image retains its aspect ratio, 
            // resize the container if you wish to change the size of the image
            //!#######################
            display: flex;
            justify-content: center;
            min-height: 273px;
            max-height: 383px;

            img {
                //!#######################
                // these settings ensure that the image retains its aspect ratio, 
                // resize the container if you wish to change the size of the image
                //!#######################
                object-fit: cover;
                min-height: 333px;
                object-position: 25% 25%;
                overflow: hidden;
            }
        }

        .selectedCardInfo {
            background-color: variables.$documentBackgroundColor;
            box-shadow: 0 0 10px 0px lightgray;
            padding: 4px;
            display: flex;
            flex-direction: column;
            column-gap: 5px;
            border-radius: 5px;
            opacity: 1;

            .name {
                line-height: 24px;
                font-weight: 700;
                font-size: 14px;
                letter-spacing: 0.1px;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }

            .seriesGroup {
                display: flex;
                justify-content: space-between;
                align-items: center;

                font-weight: 600;
                line-height: 24px;
                font-size: 12px;
                letter-spacing: 0.4px;

                .series {
                    color: variables.$accent;
                }

                .buyItNowPrice:before {
                    content: '$';
                }
            }

            .setGroup {
                display: flex;
                justify-content: space-between;
                align-items: center;

                font-weight: 300;
                font-style: normal;
                line-height: 16px;
                font-size: 12px;
                letter-spacing: 0.4px;

                .set {
                    color: variables.$accent;
                }

                .shippingCost {
                    opacity: 0.5;
                }

                .shippingCost:before {
                    content: '+$';
                }
            }

            .shippingType {
                text-align: right;
                opacity: 0.5;
                font-size: 9px;
                letter-spacing: 0.4px;
            }

            .rarity {
                display: flex;
                align-items: center;
                column-gap: 8px;
                font-weight: 400;
                font-size: 9px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #FFFFFF;

                opacity: 0.5;
            }


            @include mixins.maxWidth(900px) {}

        }
    }
}