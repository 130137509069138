.faq {
    padding: 25px 0 0 125px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 800px) {
        padding: 25px 0 0 25px;
    }

    user-asset {
        border-bottom: 1px solid #ffffff40;
    }

    .content {
        display: flex;
        max-width: 1000px;

        @media screen and (max-width: 800px) {

            flex-direction: column;
            align-items: center;
        }


        .faqMenu {
            display: flex;
            flex-direction: column;
            min-width: 200px;
            gap: 8px;

            a {
                text-decoration: none;

                &:hover {
                    color: green;
                }
            }

            @media screen and (max-width: 800px) {
                margin-top: 16px;
                border-top: 1px solid #ffffff40;
                padding-top: 8px;
                text-align: center;
            }

        }

        // .faqData {}
    }


}