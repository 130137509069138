@use './mixins.scss';
@use './variables.scss';

.searchBarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    height: 60px;
    padding: 100px 8px;
    width: 100%;
    // padding-left: 60px;
    max-width: 1170px;

    .mobileSearchBar {
        @include mixins.hideWhenNotMobile();
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .staticContent {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            padding-bottom: 8px;
        }

        .searchListingsTitle {
            text-transform: capitalize;
            text-align: center;
            flex: 1;
            user-select: none;

        }

        .back {
            color: #EE8F00;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 8px;
            width: 100px;

        }

        .title {
            display: flex;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 1.1px;
            align-items: center;
            justify-content: space-between;
            text-shadow: 0px 0px 1px;
            // padding: 0 0 16px 16px;

            width: 100%;
            height: 100%;
            text-align: center;
        }

        .searchButton {
            color: white;
        }
    }

    &>listing-search-bar {
        max-width: variables.$maxCenterWidth;
        width: 86%;
    }

    &.hideMobileSearchBar {
        padding: 0;
        display: none;
    }

    @include mixins.maxWidth800() {
        background-color: variables.$headerBarColor;
        display: flex;
        position: fixed;
        align-items: flex-end;
        width: 100%;
        max-width: 100%;
        height: auto;
        transition: all 0.2s ease;
        padding: 8px;


        &.hideSearchBar {
            transform: translateY(-100%);

            padding: 0;

            span,
            span img,
            input {
                display: none !important;
            }

            &.hideFullSearchBar {
                padding: 0;
            }
        }


    }


}

.plus {
    cursor: pointer;
}

.mainContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    flex: 0 1 100%;
    width: 100%;
    max-width: 1590px;

    @include mixins.maxWidth800() {
        // margin-top: 40px;
        margin-bottom: 0px;
        padding: 0;
    }
}