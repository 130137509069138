.comingSoon {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    min-height: 200px;

    .innerContainer {
        transition: all 300ms ease-in-out;
        border-radius: 25px;
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 110%;
        height: 110%;
        border-radius: 19px;
        border: 7px solid var(--blue, #00B1D8);
        box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.73);
        top: -10px;
        z-index: 1;
        // background: linear-gradient(0, #ffffff10, black, #ffffff10);

        .message {
            position: relative;
            bottom: 60px;
            text-transform: uppercase;
            width: 80px;
            font-family: Nunito;
            font-style: italic;
            color: #ffffff;
            font-size: larger;

            &::before {
                content: "\00a0 \00a0 \00a0";
                border-bottom: 1px solid #ffffff;
                position: relative;
                bottom: 7px;
            }

            &::after {
                content: "\00a0 \00a0 \00a0 \00a0 \00a0";
                border-bottom: 1px solid #ffffff;
                position: relative;
                bottom: 7px;
            }
        }

        .subtext {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 80%;
        }

    }

    mock-feature-indicator {
        transition: all 300ms ease-in-out;
        // transform: translateY(200%) scale(0);
    }

    .childrenContainer {
        user-select: none;
        pointer-events: none;
        opacity: 0.2;
        padding: 5px;
    }

    &:hover {
        mock-feature-indicator {
            // opacity: 1;
            // transform: translateY(0) scale(1);
        }

        .innerContainer {
            z-index: 1;
            // transform: translateY(200%) scale(0);
        }

        .childrenContainer {
            opacity: 1;
        }
    }


}