dialog {
  transition: all 1s ease;
  background-color: blue;
}

dialog::backdrop {
  transition: all 1s ease;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.1);
}