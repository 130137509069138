@use "./variables.scss";
@use "./mixins.scss";



.filterCategory {
  display: flex;
  column-gap: 6px;
  align-items: center;
  border-top: 1px solid gray;
  color: variables.$accent;
  font-size: 14px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0.4px;

  img {
    position: relative;
    top: -2px;
  }
}

.search-filters-container {
  width: 280px;
  min-height: 600px;
  min-width: 280px;
  row-gap: 10px;
  display: flex;
  flex-direction: column;

  .filterheading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    fill: variables.$primaryColor;
    color: #98fff9;
    font-size: 0.875rem;
    font-weight: 600px;
    letter-spacing: 0.4px;

    .iconHeading {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }

    a {
      height: 20;
      width: 100;
      font-weight: 500;
      font-size: 0.75rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      letter-spacing: 0.4;
      color: white;
    }

    .filterIcon {
      height: 9px;
    }
  }

  .container {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  $arrowlength: 5px;

  .search-filters {
    width: 100%;
    display: flex;
    flex-direction: column;

    .search-filter-group-header {
      cursor: pointer;
      line-height: 20px;
      font-size: 0.875rem;
      letter-spacing: 0.25px;
      font-weight: 400;

      &::after {
        border-left: 2px solid;
        border-top: 2px solid;
        width: $arrowlength;
        content: "";
        position: relative;
        top: 10px;
        height: $arrowlength;
        transform: rotate(-225deg);
        margin-right: 30px;
        margin-top: -4px;
        float: right;
        //color: variables.$primaryColor;
        color: #98fff9;
      }


      &.collapsed::after {
        transform: rotate(45deg) !important;
      }

      &.collapsed+div {
        height: 0;
        overflow: hidden;
      }
    }

    .search-filter-group {
      display: flex;
      width: 100%;
      flex-direction: column;

      .filter-search {
        margin-top: 8px;
        width: 95%;
        outline: none;
        border: 0.5px solid lightgray;
        height: 25px;
        padding-left: 10px;

        &::placeholder {
          color: lightgray;
        }

        color: gray;

        &::after {
          content: "";
          height: 14px;
          width: 15px;
          background: blue;
        }
      }

      .filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .close {
          cursor: pointer;
          padding-right: 40px;
          font-family: fa;
          font-weight: lighter;
          font-size: 10px;
        }

        label {
          margin-left: 15px;
          font-weight: 300;
          font-size: 0.75rem;
          line-height: 24px;
          letter-spacing: 0.1px;
          cursor: pointer;
        }

        input[type="checkbox"] {
          background-color: white;
          border-radius: 3px;
          appearance: none;
          border: 2px solid gray;
          width: 18px;
          height: 16.5px;
          cursor: pointer;
          position: relative;
          margin-right: 6px;
        }

        input[type="checkbox"]:checked {
          background-color: variables.$checkboxCheckedColor;
          background: variables.$checkboxCheckedColor url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 3px 3px no-repeat;
          background-position: 1px 2px;
        }
      }

      // }
    }
  }

  footer {
    margin-top: 100px;

    .footer {
      a {
        line-height: 24px;
        font-size: .8rem;
        text-decoration: none;
        color: lightgray;

        &::after {
          content: ' · ';
        }

        &:hover {
          color: variables.$primaryColor;
        }

      }



      // @include mixins.hideWhenGreaterThan600();
    }
  }
}