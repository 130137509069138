@use "./transitions";
@use "./variables";
@use "./mixins";

.filterContainerMain {
  transition: all 1s ease-in-out;
  background-color: transparent;

  .loading {
    svg {
      width: 150px;
      fill: white;
    }
  }

  .back {
    text-decoration: none;
    color: darkgray;
    font-size: 14px;
    margin-top: 15px;
    display: block;
  }

  .headerBar {
    display: flex;
    justify-content: space-between;
    z-index: 1;
  }

  .filterList {
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 490px;
    min-height: 490px;
    margin-bottom: 20px;
    color: white;


    .header {
      // margin-top: 50px;
      width: 375px;
      margin-bottom: 30px;

      .title {
        margin-bottom: 25px;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 24px;
        letter-spacing: 0.15px;
      }
    }

    .rainbowBorder {
      border-radius: variables.$rainbowBorderRadius;
      padding: 2px;
      @include transitions.animateBorderColor(180deg);

      .filter {
        border-radius: variables.$rainbowBorderRadius;
        width: 100%;
        outline: none;
        border: 0.5px solid lightgray;
        height: 40px;
        padding-left: .875em;
        color: white;
        background-color: variables.$documentBackgroundColor;

        &::placeholder {
          color: lightgray;
        }
      }

    }


    .filterItem {
      padding: 10px;
      transition: all 1s ease;
      font-size: 0.875rem;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.25);
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      letter-spacing: 0.25pxS;
      font-weight: 400;


      .hideChildFilter {
        transition: 0.5s ease-in-out;
        width: 0;
        overflow: hidden;
        margin-left: 0;
        appearance: none;
        text-align: center;
        display: none;

        a {
          transition: 0.5s ease-in-out;
          color: transparent;
          width: 0;

        }
      }

      .childFilter {
        // min-width: 80px;
        width: 50px;
        border-radius: variables.$rainbowBorderRadius;
        text-align: center;
        margin-left: 5px;
        transition: 0.5s ease;
        padding: 2px;
        @include transitions.animateBorderColor();

        line-height: 24px;

        a {
          transition: 0.5s ease-in-out;
          display: block;
          font-weight: 300;
          border-radius: variables.$rainbowBorderRadius;
          color: white;
          text-decoration: none;
          background-color: variables.$documentBackgroundColor;
        }
      }
    }

    .filterContainer {
      position: relative;
      @include mixins.scrollbar();
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}