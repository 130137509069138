@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

@property --color {
  syntax: '<color>';
  initial-value: blue;
  inherits: false;
}

@mixin slowBackground($backgroundColor, $color) {
  $time: 200ms;
  transition: background-color $time ease, color $time ease-in;

  &:hover {
    background-color: lighten($color: $backgroundColor, $amount: 0);
    color: $color;
  }

  @content
}


@mixin animateBorderColor($degree: 180deg) {
  background: conic-gradient(from var(--angle), #B8E3EE, #7F99D0, #F48265, #FFF5B8, #B8DCA2);
  background-size: 100% 100%;
  animation: 2s rotate infinite;
  animation-timing-function: linear;
  @content ;

  @keyframes rotate {
    0% {
      box-shadow: 0px 0 7px 2px #B8E3EE50;
    }

    50% {
      box-shadow: 0px 0 7px 2px #F4826550;
    }

    100% {
      --angle: 360deg;
      box-shadow: 0px 0 7px 2px #B8E3EE50;
    }
  }

}

@mixin animateBorderColorNoGlow($degree: 180deg) {

  // box-shadow: 2px -1px 20px 10px var(--color);
  // box-shadow: 0px 0 0px 0px rgba(255, 255, 255, 0.37);
  background: conic-gradient(from var(--angle), #B8E3EE, #7F99D0, #F48265, #FFF5B8, #B8DCA2);
  background-size: 100% 100%;
  animation: 2s rotate2 infinite;
  animation-timing-function: linear;
  @content ;

  @keyframes rotate2 {
    0% {
      box-shadow: 0px 0 0px 0px #B8E3EE50;
    }

    50% {
      box-shadow: 0px 0 0px 0px #F4826550;
    }

    100% {
      --angle: 360deg;
      box-shadow: 0px 0 0px 0px #B8E3EE50;
    }
  }
}

@mixin animateBorder($degree: 180deg) {
  background: conic-gradient(from var(--angle), #EE9002, transparent, #099ABA, transparent, #A000D8, transparent, #A000D8);
  background-size: 100% 100%;
  animation: 15s rotate4 infinite;
  animation-timing-function: linear;
  @content ;

  @keyframes rotate4 {
    0% {
      box-shadow: 0px 0 7px 2px transparent;
    }

    50% {
      box-shadow: 0px 0 7px 2px #EE9002;
    }

    100% {
      --angle: 360deg;
      box-shadow: 0px 0 7px 2px transparent;
    }
  }

}


@mixin imagePopout() {
  :root {
    --card-height: 300px;
    --card-width: math.div(var(--card-height), 1.5);
  }

  * {
    box-sizing: border-box;
  }

  .card {
    width: var(--card-width);
    height: var(--card-height);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 36px;
    perspective: 2500px;
    // margin: 0 50px;
  }

  .cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .wrapper {
    transition: all 0.5s;
    position: relative;
    width: 100%;
    z-index: -1;
  }

  .card:hover .wrapper {
    transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
    box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    opacity: 0.3;
  }

  .wrapper::before,
  .wrapper::after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 80px;
    transition: all 0.5s;
    position: relative;
    left: 0;
  }

  .wrapper::before {
    top: 0;
    height: 100%;
    background-image: linear-gradient(to top,
        transparent 46%,
        rgba(12, 13, 19, 0.5) 68%,
        rgba(12, 13, 19, 1) 97%)
  }

  .wrapper::after {
    bottom: 0;
    opacity: 1;
    background-image: linear-gradient(to bottom,
        transparent 46%,
        rgba(12, 13, 19, 0.5) 68%,
        rgba(12, 13, 19, 1) 97%)
  }

  .card:hover .wrapper::before,
  .wrapper::after {
    opacity: 1;
  }

  .card:hover .wrapper::after {
    height: 120px;
  }

  .title {
    width: 100%;
    transition: transform 0.5s;
  }

  .card:hover .title {
    transform: translate3d(0%, -50px, 100px);
  }

  .character {
    width: 100%;
    opacity: 0;
    transition: all 0.5s;
    position: absolute;
    z-index: -1;
  }

  .card:hover .character {
    opacity: 1;
    transform: translate3d(0%, -30%, 100px);
  }
}