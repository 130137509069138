// 
.dialog {
    height: 100%;
    width: 100%;
    z-index: 1000;

    .content {
        height: 100%;
        width: 100%;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    display: flex;
    justify-content: center;

    .controls {
        position: absolute;
        justify-content: space-between;
        top: 50%;
        left: 40px;
        width: calc(100% - 80px);

        button {
            all: unset;
        }

        display: flex;


        border: none;

        &.left {
            left: 0px;
        }

        &.right {
            right: 0px;
        }

        svg {
            circle {
                transition: all 0.3s ease-in-out;

                &:hover+g {
                    path {
                        fill: rgba(255, 255, 255, 0.926);

                    }
                }

                &:hover {
                    fill: rgba(255, 255, 255, 0.926);
                }
            }

            height: 30px;
            width: 30px;
        }
    }

}