@use './mixins.scss';


.container {
    padding: 8px;

    .subheading2 {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #FFFFFF;
        opacity: 0.5;

    }

    .rainbowChips {
        max-width: 350PX;
        color: #FFFFFF40;

    }
}