@use './variables.scss';
@use './mixins.scss';

.root {
    min-height: 600px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    column-gap: 1.875rem;
    row-gap: .875rem;

    &.selected>:not(.selected) {
        opacity: 0.2;
    }

    &.selected>:not(.selected):hover {
        opacity: 1;
    }

    &:hover>div {
        opacity: 0.2;
        transition: all 500ms ease-in-out;

    }

    >div:hover {
        opacity: 1;
    }

    .singleCard {
        background-color: #161D22;
        padding: 30px;
        width: 300px;
        border-radius: 40px;
    }

    .mixedBundle {
        background-color: #161D22;
        padding: 30px;
        width: 300px;
        border-radius: 40px;
    }

    .bundleSealed {
        background-color: #161D22;
        padding: 30px;
        width: 300px;
        border-radius: 40px;
    }


}

.actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 8px;

    span {
        font-size: smaller;
        color: variables.$lightGrayBackground2;

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    rainbow-button {
        width: 100%;
        max-width: 300px;
        height: 40px;
        color: white;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1.25px;
        border-radius: 25px;
        background-color: #252D32;
        box-shadow: 0px 1px 2px 1px #00000090;
    }
}