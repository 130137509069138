@use '../styles/variables' as v;

.root {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 0 16px;


    .closeButton {
        position: absolute;
        right: 0;
        cursor: pointer;
    }

    .heading {
        padding-bottom: 16px;

        h4 {
            margin-block-start: 10px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        // height: 200px;
        width: 100%;
        max-width: 400px;

        input {
            box-sizing: content-box !important;

        }
    }

    .actionButtons {
        display: flex;
        justify-content: flex-end;
        padding: 16px;
        column-gap: 15px;
        cursor: pointer;

        .saveButton {
            color: v.$accent;
        }
    }

}