@use "sass:math";
@use "./variables.scss";
@use "./animations.scss";

.singleCard,
.sealedSingle,
.multipleSingle,
.sealedMultiple {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: .875rem;
  align-items: center;
  background: #d9d9d9;
  border-radius: 20px;
  height: 204px;
  width: 204px;
  transition: transform 300ms ease-in-out;
  cursor: pointer;

  label {
    font-size: 20px;
    text-align: center;
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px 0 variables.$primaryColor;
  }

  &.selected,
  &.selected,
  &.selected,
  &.selected {
    background-color: variables.$primaryColor;
    color: white;
    ;
  }

}





.singleCard {
  .innerSingle {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    height: 108px;
    width: 70px;
    transition: all ease-in-out 200ms;

    img {
      height: 108px;
      width: 70px;
      opacity: 0;
      transform: scale(-1);
    }

    &:hover {
      transform: scale(1.2) rotate(180deg);

      img {
        animation: fadeIn 300ms forwards;
      }
    }
  }
}

.multipleSingle {
  row-gap: 25px;

  .left,
  .right {
    border-radius: 3px;
    height: 80px;
    width: 58px;
    position: absolute;
    bottom: 66px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      z-index: 2;
    }

    img {
      opacity: 0;
      height: 80px;
    }
  }

  .center,
  .left,
  .right {
    transition: all ease-in-out 200ms;

    &:hover {
      transform: translateY(13px) scale(1.2);

      img {
        animation: fadeIn 300ms forwards;
      }
    }
  }

  .center {
    background: #8c8c8c;
    border-radius: 3px;
    height: 105px;
    width: 70px;
    z-index: 1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    img {
      height: 105px;
      opacity: 0;
    }
  }

  .right {
    background: rgba(0, 0, 0, 0.2);
    right: 20px;
    z-index: 0;
    transform: rotate(25deg);
  }

  .left {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transform: rotate(-25deg);
    left: 20px;
    z-index: 0;
    border-radius: 8px;
  }
}

.sealedSingle {
  .center {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 125px;
    height: 84px;
    transition: all ease-in-out 200ms;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 105px;
      width: 64px;
      opacity: 0;
    }

    &:hover {
      transform: scale(1.2);
      width: 110px;
      height: 110px;

      img {
        animation: fadeIn 300ms forwards;
      }
    }
  }
}

.sealedMultiple {

  .outside,
  .inside {
    position: absolute;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    transition: all ease-in-out 200ms;
  }

  .big {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: 125px;
    height: 84px;

    img {
      height: 105px;
      width: 64px;
      opacity: 0;
    }

    // &:hover {
    //   transform: scale(1.2);
    //   z-index: 10;
    //   img {
    //     animation: fadeIn 300ms forwards;
    //   }
    // }
  }

  .inside {
    width: 40px;
    height: 55px;
    left: 75px;
    bottom: math.div(100%, 2);
    background: #8c8c8c;
    z-index: 1;

    img {
      height: 105px;
      width: 64px;
      opacity: 0;
    }

    &:hover {
      transform: scale(1.2);
      z-index: 4;
      width: 64px;
      height: 105px;

      img {
        animation: fadeIn 300ms forwards;
      }
    }
  }

  .outside {
    left: 20px;
    top: 65px;
    width: 70px;
    height: 55px;
    z-index: 2;
    background-color: #878787;

    img {
      height: 105px;
      width: 64px;
      opacity: 0;
    }

    &:hover {
      transform: scale(1.2);
      z-index: 4;
      width: 64px;
      height: 105px;

      img {
        animation: fadeIn 300ms forwards;
      }
    }
  }
}