@use 'transitions' as t;
@use 'variables' as v;


.tagsContainer {
    width: 100%;
    height: 100%;

    @media screen and (max-width: 800px) {
        padding: 16px;
    }

    .autocomplete {
        box-sizing: inherit !important;
        padding: 16px;
        // max-width: 600px;

        svg {
            fill: white !important;

        }

        label {
            color: white !important;
        }

        div {
            span {
                color: white !important;
                mix-blend-mode: exclusion !important;
            }
        }
    }

    .counter {
        display: flex;
        justify-content: flex-end;
        color: v.$white75;
        font-size: small;
    }

    .paper {

        @include t.animateBorderColor;
        border-radius: 15px;

        padding: 2px;

        .innerPaper {
            border-radius: 15px;
            background-color: v.$documentBackgroundColor;
        }
    }
}