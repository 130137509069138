@use 'variables';

@mixin minWidth($maxWidth) {
  @media (min-width: $maxWidth) {
    @content;
  }
}

@mixin minWidth1001() {
  @media (min-width: 1001px) {
    @content;
  }
}

@mixin maxWidth1300() {
  @media (max-width: 1300px) {
    @content;
  }
}

@mixin maxWidth1000() {
  @media (max-width: 1000px) {
    @content;
  }
}

@mixin maxWidth800() {
  @media (max-width: 800px) {
    @content;
  }
}

@mixin minWidth601() {
  @media (min-width: 601px) {
    @content;
  }
}

@mixin maxWidth($maxWidth) {
  @media (max-width: $maxWidth) {
    @content;
  }
}

@mixin maxWidth600() {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin minWidth441() {
  @media (min-width: 601px) {
    @content;
  }
}

@mixin maxWidth440() {
  @media (max-width: 440px) {
    @content;
  }
}

@mixin whenWidthLessThanAndHeightGreaterThan($width, $height) {
  @media (max-width: $width) and (min-height: $height) {
    @content;
  }
}


@mixin hideWhenMobile {
  @media (max-width: 800px) {
    display: none;
    @content;
  }
}

@mixin hideWhenNotMobile {
  @media (min-width: 800px) {
    display: none;
    @content;
  }
}


@mixin hideWhenLessThan440() {
  @media (max-width: 440px) {
    display: none;
    @content;
  }
}

@mixin hideWhenGreaterThan440() {
  @media (min-width: 440px) {
    display: none;
    @content;
  }
}

@mixin hideWhenGreaterThan800() {
  @media (min-width: 800px) {
    display: none;
    @content;
  }
}

@mixin hideWhenGreaterThan600() {
  @media (min-width: 600px) {
    display: none;
    @content;
  }
}

@mixin hideWhenLessThan600() {
  @media (max-width: 600px) {
    display: none;
    @content;
  }
}

@mixin hideWhenLessThan800() {
  @media (max-width: 799px) {
    display: none;
    @content;
  }
}

@mixin hideWhenLessThan($number) {
  @media (max-width: $number) {
    display: none;
    @content;
  }
}

@mixin scrollY() {
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin scrollbar($color: darken(variables.$documentBackgroundColor, 10%)) {

  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: $color;

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {

    background: variables.$documentBackgroundColor;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color;
    border-radius: 20px;
    border: 3px solid variables.$documentBackgroundColor;
  }
}