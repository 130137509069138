@use "./variables.scss";

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
  color: variables.$logoColor;
  margin-left: 15px;
  margin-top: 4px;
  column-gap: 10px;
  min-width: 150px;
}