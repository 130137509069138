@use "variables";
@use "mixins";
@use "transitions";

.root {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;

  .mobileSearchBar {
    outline: unset;
    height: 30px;
    background: variables.$documentBackgroundColor;
    padding: 0 10px;
    border-radius: 25px;

    border: none;
    color: white;
    width: 100%
  }
}

.searchBarBox {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  .iconButtonGroup {
    @include mixins.hideWhenGreaterThan600();
    display: flex;
    justify-content: space-around;
    min-width: 60px;
    align-items: center;

    a {
      height: 100%;
      color: white;
      fill: white;
      text-decoration: none;
    }
  }

  .searchBarContainer {
    display: flex;
    position: relative;
    align-items: center;
    padding: 2px;
    width: 100%;
    max-width: variables.$maxCenterWidth;
    border-radius: 15px;

    @include transitions.animateBorderColor();
  }


  .searchBarContainer:has([aria-expanded=true]),
  .searchBarContainer [aria-expanded=true],
  .searchBarContainer [aria-expanded=true] div {
    padding-bottom: 0;
    box-shadow: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

  }

  .searchBarContainer [aria-expanded=true] {
    border-bottom: 0px;
  }

  .filter-icon {
    margin-right: 15px;
    @include mixins.hideWhenLessThan600();
  }

  .searchBarButton {
    height: 37px;
    width: 116px;
    @include mixins.hideWhenLessThan600();
  }

  .placeAdButton {
    height: 37px;
    width: 116px;
    @include mixins.hideWhenLessThan600();
  }

  .searchIcon {
    margin-right: 15px;
    cursor: pointer;
    @include mixins.hideWhenGreaterThan600();
  }

  .searchBar {
    background: #1b2329;
    border-radius: 15px;
    width: 100%;

    >div {
      border-radius: 15px;
      border-bottom: unset;

      &:before {
        border-bottom: unset !important;
      }

      &:after {
        border-bottom: unset !important;
      }

      >div button {
        color: white;
        padding-right: 20px;
      }
    }


    &:focus-visible {
      outline: none;
    }

    input {
      padding: 0 0 0 15px !important;

      height: 33px;
      font-size: .8em;
      color: white;
    }
  }
}

@include mixins.maxWidth600() {
  .root {
    width: 100%;
    padding: 0;
  }

  .searchBarBox {
    display: flex;
    justify-content: center;
    column-gap: 0px;

    .searchBarContainer,
    .searchBarContainer input {
      border-radius: 15px;
      transition: background-color 1s ease;
    }

    .searchBarContainer {
      background-color: #035a8512;
      border-color: #035a8512;

      &:focus-within {
        outline: none !important;
        background-color: white;
      }
    }

    input {
      background-color: transparent;

      &::placeholder {
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }

    .search-icon {
      margin-right: 0;
      width: 25px;
    }

    .searchBarContainer {
      width: 80%;
    }
  }
}