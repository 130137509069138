@use './variables.scss';
@use './mixins.scss';
@use './transitions.scss';

.dropDown {
    @include transitions.animateBorderColor();
    border-end-end-radius: variables.$rainbowBorderRadius;
    border-end-start-radius: variables.$rainbowBorderRadius;
    padding: 0 1px 1px 1px;
    width: 653px;
    position: absolute;

    .dropdownContent {
        color: 'white' !important;
        max-height: '200px';
        overflow-y: 'auto';
        position: 'relative';
        background-color: variables.$documentBackgroundColor;
    }
}

.autoCompleteRoot {
    display: flex;
    position: relative;
    width: 100%;
    z-index: 1;

    .autoComplete {
        position: absolute;
        border-radius: variables.$rainbowBorderRadius;
        width: 100%;
        top: -20px;

        @include mixins.maxWidth800() {
            position: relative;
            top: unset;
        }

        @include transitions.animateBorderColor();
        padding: 2px;


        .inputContainer {
            width: 100%;
            border-start-end-radius: variables.$rainbowBorderRadius;
            border-start-start-radius: variables.$rainbowBorderRadius;
            padding-right: 15px;

            display: flex;
            align-items: center;


            input {
                all: unset;
                width: 94%;
                box-sizing: border-box;
                font-size: 1.3rem;
                background-color: transparent;
                padding: 0px 35px;
                height: 40px;

                // &:focus {
                //     background: linear-gradient(90deg, #ffffff99 60%, transparent);
                //     color: variables.$documentBackgroundColor;
                //     border-start-start-radius: 25px;
                //     border-end-start-radius: 25px;
                // }
            }

            button {
                appearance: none;
                background-color: transparent;
                border: none;
                color: white;
                height: 20px;

            }

            @include mixins.maxWidth800() {
                input {
                    font-size: 1.0rem;
                }


                .hideWhenMobile {
                    display: none;
                }
            }
        }

        .autoCompleteInner {
            border-radius: variables.$rainbowBorderRadius;
            background-color: variables.$documentBackgroundColor;
            position: relative;
        }

        .autoCompleteContainer {
            display: flex;
            flex-direction: column;
            position: relative;
            background-color: variables.$documentBackgroundColor;
            border-radius: 25px;
            z-index: 1;

            .divider {
                height: 6px;
                background: linear-gradient(180deg, #ffffff00, #00000065);
            }

            .autoCompleteOption {
                user-select: none;
                column-gap: 35px;
                line-height: 39px;
                font-size: 1.3rem;
                padding: 0px 35px;

                &:hover,
                &.highlighted {
                    background-color: variables.$highlightedBackgroundColor;
                }

                @include mixins.maxWidth440() {
                    font-size: 1.0rem;
                    padding: 0px 15px;
                }

            }
        }
    }
}


.alternativePicker {
    position: absolute;
    // margin-top: 40px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: white;
    background: variables.$documentBackgroundColor;

    .autoCompleteContainer {

        .autoCompleteOption {
            user-select: none;
            column-gap: 35px;
            line-height: 39px;
            font-size: 1.3rem;
            padding: 0px 35px;

            &:hover,
            &.highlighted {
                background-color: variables.$highlightedBackgroundColor;
            }

            @include mixins.maxWidth440() {
                font-size: 1.0rem;
                padding: 0px 15px;
            }

        }
    }
}