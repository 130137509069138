@use "./variables.scss";
@use "./mixins.scss";
@use "./transitions.scss";
@use "./animations.scss";

$borderRadius: 10px;
$color: variables.$primaryColor;
$color-dark: scale-color($color, $lightness: -30%);
$transition: 280ms all 120ms ease-out;
$transition: none;
$linear-gradient: linear-gradient(90deg,
    rgba(222, 61, 61, 1) 0%,
    rgba(83, 242, 61, 1) 100%);

.gradesContainer {

  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  column-gap: 8px;
  // height: 160px;
  color: #FFFFFF40;

  .gradesHalfgrades {
    display: flex;
    column-gap: 12px;
    position: absolute;

  }

  button {
    all: unset;
  }

  .text {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    text-align: center;
    letter-spacing: 0.15px;

    /* White */

  }

  .dropdown {
    transition: all 500ms ease-in-out;
    border-radius: 10px;
    padding: 2px;

    .halfGrades {
      display: none;
      flex-direction: column;
    }

    &.selected {
      .gradeContainer .halfGrades {
        display: flex;
      }

      .grade,
      .halfGrades {
        &:not(.selected) {
          color: darken($color: #FFFFFF40, $amount: 60);

          &:hover {
            color: white;
          }
        }
      }


      .selected {
        color: white;
      }


      display: flex;
      @include transitions.animateBorderColor();

      .selected {
        color: white;
      }
    }


    transition: all 500ms ease-in-out;

    .gradeContainer {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      background-color: variables.$documentBackgroundColor;
      padding: 5px;
      min-height: 80px;
      position: relative;

      >div {
        min-width: 45px;
        text-align: center;
        padding: 5px;


        &:hover {
          border-radius: 10%;
          color: white !important;
        }
      }
    }

    .grade {
      border-top-left-radius: $borderRadius;
      border-top-right-radius: $borderRadius;
    }

    .halfGrades {
      border-bottom-left-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;
    }

    .grade,
    .halfGrades span {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 30px;

      cursor: pointer;
    }

    &:hover {
      background-color: white;
    }

    &:hover .gradeContainer .halfGrades {
      display: flex;

    }
  }

}



@include mixins.maxWidth1000() {

  .gradesContainer {
    .gradesHalfgrades {
      column-gap: 2px;
    }

    .text {
      // font-size: 1rem;
    }

    .dropdown {
      // width: 50px;

      .gradeContainer {
        padding: 1px;

        >div {
          min-width: 40px;
        }
      }


    }
  }
}