@use '../../../styles/variables';
@use '../../../styles/mixins';
@use '../../../styles/transitions';

.root {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-around;
    height: 80px;

    @media screen and (max-width: 800px) {
        height: 100%;
    }

    width: 100%;
    max-width: 100%;
    column-gap: 8px;


    .mobileBackButton {
        display: none;
    }
}

.searchBarBox {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;

    .iconButtonGroup {
        @include mixins.hideWhenGreaterThan600();

        button {
            color: white;
            fill: white;

            svg {
                border-radius: 50px;
                border: 1px solid white;
            }
        }
    }

    .searchBarContainer {
        display: flex;
        position: relative;
        align-items: center;
        padding: 2px;
        width: 100%;
        max-width: variables.$maxCenterWidth;
        border-radius: 15px;

        @include transitions.animateBorderColor();
    }

    .searchBarContainer:has([aria-expanded=true]),
    .searchBarContainer [aria-expanded=true],
    .searchBarContainer [aria-expanded=true] div {
        padding-bottom: 0;
        box-shadow: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

    }

    .searchBarContainer [aria-expanded=true] {
        border-bottom: 0px;
    }

    .filter-icon {
        margin-right: 15px;
        @include mixins.hideWhenLessThan600();
    }

    .searchBarButton {
        height: 37px;
        width: 116px;
        @include mixins.hideWhenLessThan600();
    }

    .placeAdButton {
        height: 37px;
        width: 116px;
        @include mixins.hideWhenLessThan600();
    }

    .searchIcon {
        margin-right: 15px;
        cursor: pointer;
        @include mixins.hideWhenGreaterThan600();
    }

    .searchBar {
        background: #1b2329;
        border-radius: 15px;
        width: 100%;

        >div {
            border-radius: 15px;
            border-bottom: unset;

            &:before {
                border-bottom: unset !important;
            }

            &:after {
                border-bottom: unset !important;
            }

            >div button {
                color: white;
                padding-right: 20px;
            }
        }


        &:focus-visible {
            outline: none;
        }

        input {
            padding: 0 0 0 15px !important;

            height: 33px;
            font-size: .8em;
            color: white;
        }
    }
}

@include mixins.maxWidth800() {
    .root {
        width: 100%;
        padding: 0 16px;
        margin-bottom: 8px;
        background-color: #252D32;

        .mobileBackButton {
            padding-right: 10;
            display: unset;
        }

        .searchBarBox {
            display: flex;
            justify-content: center;
            column-gap: 0px;

            .searchBarContainer,
            .searchBarContainer input {
                border-radius: 15px;
                transition: background-color 1s ease;
            }

            .searchBarContainer {
                background-color: #035a8512;
                border-color: #035a8512;

                &:focus-within {
                    outline: none !important;
                    background-color: white;
                }
            }

            input {
                background-color: transparent;

                &::placeholder {
                    color: rgba(0, 0, 0, 0.25) !important;
                }
            }

            .search-icon {
                margin-right: 0;
                width: 25px;
            }

            .searchBarContainer {
                width: 80%;
            }
        }
    }
}