.sellerActions {
    width: 60vw;
    padding: 25px 0 0 125px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 20px;

    @media screen and (max-width: 800px) {
        padding: 25px 0 0 25px;
    }
}