@use 'mixins';

.container {
    display: flex;
    align-items: center;
    column-gap: 10px;
    row-gap: 10px;
    justify-content: space-around;
    width: 100% !important;
    padding-bottom: 20px;
    padding-top: 20px;

    .children {
        width: 100%;
        max-width: 1000px;
        @include mixins.scrollbar();

    }

    rainbow-button {
        max-width: 180px;
        flex: 1 0 150px;
    }


    @media (min-width: 1000px) {
        rainbow-button {
            width: 180px;
        }
    }


    @include mixins.maxWidth800() {
        flex-wrap: wrap;

        &>div:nth-child(1) {
            display: none;
        }

        &>div:nth-child(3) {
            width: 100%;

        }

        rainbow-button {
            max-width: 100%;
            flex: 1 0 150px;
        }
    }


}