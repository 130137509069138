.videoContainer {
    position: relative;

    .video {
        max-width: 272px;
        max-height: 204px;
        min-width: 272px;
        min-height: 204px;

    }

}