@use "./variables.scss";

.fileUploadContainer {

  input[type="text"] {
    background: variables.$lightGrayBackground;
    border-radius: 30px;
    border: none;
    width: 79%;
    height: 30px;

    &:focus-visible {
      outline: none;
    }
  }

  span {
    color: #ffffff40
  }

  .removeIconButton {
    display: none;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 30px;
    height: 30px;
    background-color: black;

    .removeIcon {
      color: white;
    }

    &.permanent {
      display: flex;

      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }



  .previewIconButton {
    display: none;
    position: absolute;
    left: 10px;
    top: 0px;
    width: 10px;

    .previewIcon {
      color: white;
    }

  }

  &:hover .removeIconButton {
    display: flex;
    color: white;
  }



  .backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &:hover .backdrop {
    height: 100%;
    border-radius: 7px;
    background-color: rgba(27, 27, 27, 0.411);
    background: linear-gradient(#000000de, #ffffff3d);
    background: #000000b0
  }

  .fileUpload {
    height: 160px;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 3px solid #ffffff40;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    @media screen and (max-width: 800px) {
      border-width: 1px;
      height: 120px;
      width: 82.5px;
    }



    .iconContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 12px;
      line-height: 30px;

      .uploadIcon {
        height: 20px;
        width: 20px;
        fill: #ffffff40;
      }
    }

    img {
      height: 100%;
      object-fit: cover;
      object-position: 25% 25%;
    }
  }
}