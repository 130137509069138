@use '../styles/variables' as v;

.dark {
    color: v.$fontColorMain !important;
    background-color: v.$secondaryMain !important;



}


.context {
    color: v.$fontColorMain !important;
    border: 1px solid white !important;

    ul {
        border-radius: 4px !important;
        background-color: v.$secondaryMain !important;
    }

}


.menuItem {
    display: flex;

    justify-content: flex-start;

    svg {
        width: 30px;
        fill: white;
    }


}