@use "sass:math";

$baseFontSize: 16;
$maxCenterWidth: 1045px;
$documentBackgroundColor: #1a2329;
$highlightedBackgroundColor: rgba(107, 107, 107, 0.25);
$accent: #98FFF9;
$secondary: #ffffff40;
$secondaryMain: #252D32;
$fontColorMain: #ffffff;
$white75: #C5C6C8; //75% white passthrough
$primaryColor: white;
$headerBarColor: #252D32;
$logoColor: $primaryColor;
$checkboxCheckedColor: $primaryColor;
$mainbuttoncolor: $primaryColor;
$activeBackgroundColor: $primaryColor;
$validationError: red;
$borderColor: #cfcfcf;
$lightGrayBackground: #f5f5f5;
$lightGrayBackground2: #c5c5c5;
$mobileButtonBorderRadius: 37px;
$rainbowBorderRadius: 25px;
$pixels12: 0.75rem;

@function pixelToRem($pixels) {
    @return #{math.div($pixels,$baseFontSize)}rem;
}