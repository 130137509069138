.textField {
    height: 35px;
    border: 2px solid #FFFFFF40;
    border-radius: 10px;


    input {
        color: white;
        background-color: transparent;
        border: unset;
        height: 100%;
        outline: none;
        padding-left: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.18px;

        &::placeholder {
            font-weight: 400;
            opacity: 0.25;
            color: #FFFFFF;
        }
    }

}