@use 'variables' as v;

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 1.25rem;

    .body {
        font-family: Roboto, sans-serif;
        padding: 20px;
        background-color: #1a2329;
        color: white;

        .header {
            h1 {
                margin: 0;
            }

            display: flex;
            justify-content: flex-start;
            margin-bottom: 16px;

        }


        section {
            display: flex;

            h2 {
                margin: 0;
            }

            .image {
                display: flex;
                background-color: blue; //v.$highlightedBackgroundColor;
                height: 100%;
            }

            .content {
                padding-left: 24px;
                justify-content: space-between;
                display: flex;
                flex-direction: column;

                .buttonContainer {
                    display: flex;
                    flex-wrap: wrap;

                    rainbow-button {
                        min-width: 200px;
                        max-width: 300px;
                        flex: 1;

                        margin-right: 16px, ;
                        text-decoration: none;
                        color: white;
                        padding: 8px 16px;
                        border-radius: 4px;
                        height: 50px;
                    }
                }
            }
        }
    }
}