@use "../styles/variables";


.heading {
    display: block;
    width: 100%;
    height: 50px;
    margin-bottom: 15px;
}

.dialog {
    min-height: auto !important;
}


listing-message-dialog {
    display: flex;
    padding: 10px 40px;
    flex-direction: column;
    row-gap: 15px;

    .actionButtons {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        a {
            cursor: pointer;

            &.postButton {
                color: variables.$accent
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.comment {
    display: flex;
    height: 100px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    width: 100%;
    max-width: 450px;
    width: 100%;
    background-color: variables.$documentBackgroundColor;
    color: white;
    padding: 15px;
    margin: 15px 0;
    border-radius: 4px;
}