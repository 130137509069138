.container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 20px;


    .buyItNowPrice {
        width: 300px;
    }

    .MarketDesc {
        font-size: 0.875rem;
        font-weight: 400;
        letter-spacing: 0.15px;
        font-style: italic;
        line-height: 24px;
        color: rgba(113, 128, 112, 0.87);

    }

    .priceTileContainer {
        margin-top: 15px;
        // width: 100%;
        // min-width: 80px;
        max-width: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        appearance: none;
        background-color: transparent;
        border: none;
        color: white;
        padding: 5px;

        button {
            appearance: none;
            background-color: transparent;
            border: none;
            color: white;
            height: 20px;

        }

        .newPrice {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 24px;
            /* identical to box height, or 133% */

            text-align: center;
            letter-spacing: 0.1px;

            /* White */

            color: #FFFFFF;


        }

        .growthRate {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 24px;
            /* identical to box height, or 200% */

            text-align: center;
            letter-spacing: 0.1px;

            color: rgba(113, 128, 112, 0.87);

        }

        .label1 {
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 24px;
            /* identical to box height, or 240% */

            text-align: center;
            letter-spacing: 0.1px;

            /* Main blue*/

            color: #98FFF9;
        }

        .desc1 {
            font-weight: 300;
            font-size: 0.75rem;
            line-height: 24px;
            /* identical to box height, or 240% */

            text-align: center;
            letter-spacing: 0.4px;

            /* White */

            color: #FFFFFF;
        }

        .desc2 {
            font-weight: 300;
            font-size: 0.75rem;
            line-height: 24px;
            /* identical to box height, or 240% */

            text-align: center;
            letter-spacing: 0.4px;

            /* White */

            color: #FFFFFF;
        }

        // &:not(:first-child):hover {
        //    background-color: #252D32;
        //    border-radius: 10px;
        //}

    }
}