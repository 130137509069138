.throbber {

    [class^="file-upload_fileUpload_"] {
        animation: pulse 1.5s infinite;
        animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
    }




}

@keyframes pulse {
    0% {
        // transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 226, 63, 0.253);
    }

    70% {
        // transform: scale(1);
        box-shadow: 0 0 0 20px rgba(255, 121, 63, 0);
    }

    100% {
        // transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
}