 @use './variables' as v;
 @use './mixins';
 @use './transitions';
 @use './animations' as a;
 @use './image-slider.module.scss' as image-slider;

 .sellerListings {
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     max-width: 875px;
     width: 100%;


     .title {
         width: 100%;
         font-weight: 700;
         padding-left: 30px;
         padding-top: 20px;
         padding-bottom: 20px;
     }

     .content {
         display: flex;


     }

     .sellerItem {
         width: 250px;

         @media screen and (max-width: 800px) {
             width: 178px;
         }
     }
 }


 .headerTitle {
     //  margin-top: 50px;
     padding: 16px 0;

     h1 {
         height: 32px;
     }
 }


 .content {
     width: 100%;
     display: flex;
     justify-content: center;
     flex-wrap: wrap;
     column-gap: 20px;
     row-gap: 20px;

     .card {
         display: flex;
         flex-wrap: wrap;
         justify-content: center;
         width: 100vw;
         column-gap: 20px;
         max-width: 875px;


         .leftContainer {
             display: flex;
             justify-content: flex-start;
             flex-direction: column;
             width: v.$maxCenterWidth;
             column-gap: 20px;
             max-width: 1000px;
             width: 320px;

             .actionButtons {
                 display: flex;
                 flex-direction: column;
                 row-gap: 16px;

                 padding: 16px 0px;

                 rainbow-button {
                     min-width: 345px;
                 }
             }

             .imageContainer {
                 display: flex;
                 flex-direction: column;
                 position: relative;
                 background-color: #24313A;
                 outline-color: #161D22;
                 outline-style: solid;
                 outline-width: 5px;
                 width: 340px;
                 border-radius: 10px;
                 height: 475px;
                 row-gap: 16px;

                 rainbow-button {
                     button {
                         height: 31px;
                     }
                 }
             }

             .imagePanel {
                 @include mixins.hideWhenLessThan(1400px);
             }

             .imageGallery {
                 position: relative;
                 color: #24313A;
                 background-color: #24313A;
                 margin-top: 50px;
                 border-radius: 10px;
                 display: flex;
                 flex-wrap: wrap;
                 column-gap: 5px;
                 justify-content: center;

                 .container {
                     display: flex;
                     flex-wrap: wrap;
                     align-items: flex-start;
                     justify-content: flex-start;
                     width: 100%;
                     height: 100%;

                     .imagePreview {


                         transition: all 0.2s ease-in-out;

                         img {
                             cursor: pointer;
                             width: calc(375px / 5);
                             height: calc(200px / 2);
                             object-fit: contain;

                         }


                         &.selected {
                             @include transitions.animateBorderColor;

                         }

                         &:hover {

                             transform: scale(1.1);
                             box-shadow: 0 0 6px rgba(255, 255, 255, 0.5);
                             background-color: rgba(255, 255, 255, 0.5);
                             ;
                         }

                     }
                 }
             }

         }

         .userNameShipping {
             display: flex;
             justify-content: space-between;
             align-items: center;

             .shipping {
                 font-style: normal;
                 font-weight: 400;
                 line-height: 16px;
                 text-align: right;
                 letter-spacing: 0.40px;
                 color: #C5C6C8;
                 font-size: .875rem;
                 font-weight: 400;
             }

         }

         .location {

             font-size: 0.75rem;
             font-style: normal;
             font-weight: 400;
             line-height: 1.5rem;
             letter-spacing: 0.025rem;
         }


         .cardDetailsAndConditionGroup {
             padding: 8px 0;
             background: #161D22;
             display: grid;
             grid-template-columns: repeat(3, auto);
             grid-template-rows: repeat(2, auto);
             gap: 0px;
             row-gap: 8px;

             .gridRowDivider {
                 grid-column: 1 /4
             }


             .seriesname {
                 font-size: 0.75rem;
                 color: v.$accent;
                 font-style: normal;
                 font-weight: 500;
                 line-height: 1.5rem;
                 letter-spacing: 0.025rem;
                 align-items: center;
             }

             .cardNumber {
                 display: flex;
                 column-gap: 10px;
                 font-size: 0.75rem;
                 font-weight: 500;
                 line-height: 1.5rem;
                 letter-spacing: 0.025rem;
                 align-items: center;
             }

             .numbersign {
                 height: 17px;
                 width: 17px;
                 transform: translateY(-2px);
             }

             .condition {
                 display: flex;
                 column-gap: 10px;
                 font-size: 0.75rem;
                 font-weight: 500;
                 line-height: 1.5rem;
                 letter-spacing: 0.025rem;
                 align-items: center;
             }

             .conditionicon {
                 height: 19px;
                 width: 19px;
                 transform: translateY(-3px);
             }

             .setNameDetails {
                 font-size: 0.75rem;
                 font-weight: 300;
                 line-height: 1.5rem;
                 letter-spacing: 0.025rem;
                 color: v.$accent;
                 align-items: center;
             }

             .rarity {
                 font-size: 0.75rem;
                 font-weight: 300;
                 line-height: 1.5rem;
                 letter-spacing: 0.025rem;
                 column-gap: 10px;
                 display: flex;
                 align-items: center;

                 .rarityText {
                     text-overflow: ellipsis;
                     overflow: hidden;
                     white-space: nowrap;
                     width: 80%;
                 }

             }

             .gradecompany {
                 font-size: 0.75rem;
                 font-weight: 300;
                 line-height: 1.5rem;
                 letter-spacing: 0.025rem;
                 column-gap: 10px;
                 display: flex;
                 align-items: center;

             }

             .companysign {
                 height: 19px;
                 width: 19px;
                 transform: translate(1px, -2px);
             }
         }

         .description {
             display: flex;
             flex-direction: column;
             padding: 10px 0;
             font-size: 24px;
             line-height: 24px;
             letter-spacing: 0.18px;

             .content {
                 margin-top: 5px;
                 padding: 1px;
                 border-radius: 5px;
                 color: rgba(255, 255, 255, 0.75);
                 max-height: 170px;

                 font-size: 0.875rem;
                 font-weight: 300;
                 line-height: 1.25rem;
                 letter-spacing: 0.025rem;

                 overflow: hidden;
                 text-overflow: ellipsis;
                 display: -webkit-box;
                 -webkit-line-clamp: 7;
                 -webkit-box-orient: vertical;
                 line-clamp: 7;
             }
         }

         .background {
             width: 100%;
             border-radius: 5px;
             padding: 0 15px;

             .actionBar {
                 display: flex;
                 margin-top: 5px;
                 margin-bottom: 10px;
                 flex-direction: row;
                 justify-content: space-between;

                 .location {
                     display: flex;
                     column-gap: 5px;

                 }

                 .divider {
                     display: flex;
                 }

                 .buttonBar {
                     display: flex;
                     column-gap: 10px;


                     rainbow-button {
                         flex-grow: 2;
                         flex-shrink: 1;
                         //flex-basis: 200px;
                         justify-content: flex-end;
                         font-weight: 500;
                         font-size: 0.875rem;
                         letter-spacing: 1.25px;
                     }

                     .emailButton {
                         all: unset;


                         .circle {
                             // border: 2px solid white;
                             border-radius: 50%;
                             width: 30px;
                             height: 30px;
                             text-align: center;
                             display: flex;
                             align-content: center;
                             align-items: center;
                             justify-content: center;


                             &:hover {
                                 background-color: #7f01ac9c;
                                 transition: ease 0.3s;
                             }

                             .email {
                                 all: unset;
                                 width: 21px;
                                 height: 21px;
                             }

                         }
                     }

                     .favButton {
                         all: unset;

                         .circle {
                             border: 2px solid white;
                             border-radius: 50%;
                             width: 30px;
                             height: 30px;
                             text-align: center;
                             display: flex;
                             align-content: center;
                             align-items: center;
                             justify-content: center;
                             opacity: 50%;

                             /*


                         &:hover {
                             background-color: #b46c01ab;
                             transition: ease 0.3s;
                         }

                         */

                             .fav {
                                 all: unset;
                                 width: 18px;
                                 height: 18px;
                             }
                         }
                     }

                     .ellipsisButton {
                         all: unset;
                         display: flex;

                         .circle {
                             border: 2px solid white;
                             border-radius: 50%;
                             width: 30px;
                             height: 30px;
                             text-align: center;
                             display: flex;
                             align-content: center;
                             align-items: center;
                             justify-content: center;

                             &:hover {
                                 background-color: #4aabb49a;
                                 transition: ease 0.3s;
                             }

                             .ellipsis {
                                 all: unset;
                                 width: 18px;
                                 height: 18px;
                             }
                         }
                     }
                 }

                 svg {
                     width: 35px;
                 }

             }
         }

         .skeleton {
             height: 350px;
             width: 250px;
         }

         .details {
             display: flex;
             flex-direction: column;
             width: 100%;
             color: v.$primaryColor;
             max-width: 530px;

             .sold {
                 text-transform: uppercase;
                 font-weight: 700;
                 display: flex;
                 justify-content: center;
                 align-items: center;
                 background: #EE8F00;
                 color: #161D22;
                 //  box-shadow: 0px 0px 0px 5px v.$highlightedBackgroundColor;
                 z-index: 1;
                 border-radius: 5px;
                 width: 540px;
                 font-size: 40px;
                 height: 80px;
                 margin-bottom: 5px;
                 border: 5px solid v.$secondaryMain;
             }

             .title {
                 display: flex;
                 justify-content: space-between;
                 width: 100%;
                 overflow: hidden;
                 flex-direction: row;
                 padding-bottom: 8px;


                 .name {
                     font-weight: 700;
                     font-size: 24px;
                     line-height: 24px;
                     letter-spacing: 0.18px;
                 }

                 .setname {
                     color: v.$accent;
                     font-weight: 500;
                     font-size: .875rem;
                     letter-spacing: 0.4px;

                 }


                 .price {
                     font-style: normal;
                     font-weight: 400;
                     font-size: 34px;
                     line-height: 36px;
                     text-align: right;
                 }
             }



             .set {
                 span {
                     color: v.$accent;
                     font-weight: 400;
                     font-size: 12px;
                     line-height: 24px;
                     letter-spacing: 0.1px;
                 }
             }

         }

     }

     .priceTileContainer {
         margin-top: 15px;

         width: 150px;
         display: flex;
         flex-direction: column;
         justify-content: space-evenly;
         align-items: center;
         appearance: none;
         background-color: transparent;
         border: none;
         color: white;
         padding: 5px;

         button {
             appearance: none;
             background-color: transparent;
             border: none;
             color: white;
             height: 20px;

         }

         .newPrice {
             font-weight: 500;
             font-size: 0.875rem;
             line-height: 24px;
             /* identical to box height, or 133% */

             text-align: center;
             letter-spacing: 0.5px;

             /* White */

             color: #FFFFFF;


         }

         .growthRate {
             font-weight: 400;
             font-size: 0.875rem;
             line-height: 24px;
             /* identical to box height, or 200% */

             text-align: center;
             letter-spacing: 0.5px;
             color: rgba(113, 128, 112, 0.87);

         }


         .label1 {
             font-weight: 500;
             font-size: 0.75rem;
             line-height: 24px;
             /* identical to box height, or 240% */
             text-align: center;
             letter-spacing: 0.1px;

             /* Main blue*/

             color: #98FFF9;
             padding-bottom: 5px;
         }

         .desc1 {
             font-weight: 300;
             font-size: 0.75rem;
             line-height: 24px;
             /* identical to box height, or 240% */

             text-align: center;
             letter-spacing: 0.4px;

             /* White */

             color: #FFFFFF;
         }

         .desc2 {
             font-weight: 300;
             font-size: 0.75rem;
             line-height: 24px;
             /* identical to box height, or 240% */

             text-align: center;
             letter-spacing: 0.4px;

             /* White */

             color: #FFFFFF;
         }

         // &:not(:first-child):hover {
         //    background-color: #252D32;
         //    border-radius: 10px;
         //}

     }

 }

 @include mixins.maxWidth600() {
     .card {

         .background {
             width: 100%;

             .actionBar {
                 row-gap: 10px;
                 flex-direction: column;

                 .favButton,
                 .ellipsisButton,
                 rainbow-button {
                     position: relative;
                     width: 100%;
                     flex: 1 0 100%;
                     //height: 46px;
                     flex-wrap: wrap;
                     justify-content: center;
                     border-radius: 5px;

                 }

                 .favButton,
                 .ellipsisButton {
                     border: 1px solid #ffffff40;
                     line-height: 25px;
                 }



             }
         }
     }
 }

 @include mixins.maxWidth800() {
     .content {
         width: 100%;
         display: flex;
         justify-content: center;
         flex-wrap: wrap;
         column-gap: 20px;
         row-gap: 20px;
         margin-top: 0px;

         .card {
             flex-direction: column;
             justify-content: center;
             align-items: center;
             margin-top: 0 !important;

             .title {
                 flex-direction: column;
             }

             .background {
                 .details {
                     max-width: 100%;
                 }
             }



             .details {
                 align-items: center;
                 max-width: 100% !important;
             }

             .leftContainer {
                 display: flex;
                 justify-content: center;
                 align-items: center;
                 width: 100vw;

                 .imageContainer {
                     width: 100vw;
                     max-width: 340px;
                     height: 100%;
                     // padding-bottom: 16px;

                 }
             }
         }
     }
 }