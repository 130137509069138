@use './variables.scss';

.root {
    display: flex;
    width: 100%;


    button {
        all: unset;
        text-align: center;
        width: 100%;
        height: 100%;
    }

    .option {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 10px;

        width: 168px;
        height: 40px;
        color: #ffffff40;
        border: 1px solid #ffffff40;
        /* MainLightBlue */

        &.selected {
            animation: backgroundColorAnimation 0.5s ease-in-out;

            background-color: variables.$accent;
            border: 1px solid rgba(255, 255, 255, 0.25);
            color: variables.$documentBackgroundColor;
        }

        &:nth-child(1) {
            border-radius: 100px 0px 0px 100px;
        }

        &:nth-child(2) {
            border-radius: 0px 100px 100px 0px;
        }

        /* Inside auto layout */

        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 1;
    }
}


@keyframes backgroundColorAnimation {
    0% {
        box-shadow: 0px 0px 14px 4px variables.$accent;
        background-color: variables.$documentBackgroundColor;
    }

    50% {
        box-shadow: 0px 0px 14px 4px variables.$accent;
    }

    100% {
        background-color: variables.$accent;
    }
}