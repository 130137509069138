@use './variables.scss';

.previewRoot {

    .table {
        .row:nth-child(2) {
            font-size: 1rem;
            border-bottom: 1px solid white;

            .cellPost {
                font-size: 0.625rem;
                letter-spacing: 0.025rem;
                line-height: 1rem;
                color: #ffffff80;
            }

        }

        .row:nth-child(n+3) {
            color: #ffffff80;
            border-bottom-style: dashed;
            border-bottom-color: #ffffff40;
            border-bottom-width: 1px;

        }

        .row:nth-child(n+8) {
            //opacity: .5;
            color: white;
            background-color: #161D22;
            border-radius: 4px;
            border-bottom-style: dashed;
            border-bottom-color: #161D22;
            border-bottom-width: 1px;

        }

        .row .cell:nth-child(3) {
            border-left: 1px solid white;
        }

        .headerRow,
        .row {
            font-size: 0.75rem;
            letter-spacing: 0.025rem;
            display: flex;
            // max-width: 600px;
            // width: 100%;

            .cell:nth-child(1) {
                flex: 1;
                // max-width: 230px;
                // width: 100%;

            }

            .cell:nth-child(2) {
                flex: 1 1;
                // max-width: 130px;
                // width: 100%;
                text-align: right;
            }

            .cell:nth-child(3) {
                flex: 1 1;

                text-align: left;
                // display: none;
            }
        }

        .headerRow {
            .cell {
                text-transform: uppercase;
                color: variables.$accent;
                padding: 0 5px;
                font-size: 0.625rem;
                font-weight: 500;
                line-height: 1rem;
                letter-spacing: 0.09375rem;
            }
        }

        .row {
            .cell {
                padding: 2px 10px;
            }
        }
    }
}