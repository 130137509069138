.chipsCollection {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;


    .chip {
        z-index: 0;


        &:hover {
            outline: 1px solid #FFFFFF40;
        }

        button {
            width: 150px !important;
        }
    }

    .highlight {
        z-index: 1;
        padding: 1px;
        color: #FFF !important;
    }
}