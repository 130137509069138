@use "../../styles/variables" as v;

.userAsset {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
        flex-direction: column;
    }

    .content {
        display: flex;
        row-gap: 10px;
        column-gap: 5px;
        align-items: center;
        width: 100%;
        max-width: 550px;
        align-items: flex-start;
        padding-top: 10px;

        img {
            width: 85px;
            height: 110px;
            object-fit: cover;
            border-radius: 8px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

            @media screen and (max-width: 500px) {
                width: 50px;
                height: 70px;
            }
        }


        .assetInfo,
        .assetInfo2 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: v.pixelToRem(12);
            letter-spacing: 0.4px;
            line-height: 16px;
        }


        .assetInfo {
            width: 172px;
            font-size: v.pixelToRem(12);
            line-height: v.pixelToRem(16);
            padding-left: 5px;

            @media screen and (max-width: 500px) {
                width: 120px;
            }



            .line1 {
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem;
                letter-spacing: 0.03125rem;
                padding-bottom: 5px;

                @media screen and (max-width: 500px) {
                    font-family: Roboto;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1rem;
                    letter-spacing: 0.025rem;
                }
            }



            .line2 {
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5rem;
                letter-spacing: 0.00625rem;
                color: v.$accent;

                @media screen and (max-width: 500px) {
                    font-size: 0.625rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1rem;
                    letter-spacing: 0.025rem;
                }
            }

            .line3 {
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5rem;
                letter-spacing: 0.00625rem;

                @media screen and (max-width: 500px) {
                    font-size: 0.625rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1rem;
                    letter-spacing: 0.025rem;
                }
            }

            .line4 {
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5rem;
                letter-spacing: 0.00625rem;

                @media screen and (max-width: 500px) {
                    font-size: 0.625rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1rem;
                    letter-spacing: 0.025rem;
                }
            }

        }

        .assetInfo2 {

            row-gap: 2px;

            >div {

                >span:nth-child(1) {
                    display: inline-block;
                    width: 120px;

                    @media screen and (max-width: 500px) {
                        width: 80px;
                    }
                }

                >span:nth-child(2) {
                    display: inline-block;
                    width: 120px;

                    @media screen and (max-width: 500px) {
                        width: 80px;
                    }
                }
            }

            .purchasePrice {
                display: flex;
                justify-content: center;
                align-items: center;

                >span:nth-child(1) {
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.25rem;
                    letter-spacing: 0.01563rem;

                    @media screen and (max-width: 500px) {
                        font-size: 0.625rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1rem;
                        letter-spacing: 0.025rem;
                    }

                }

                >span:nth-child(2) {
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5rem;
                    letter-spacing: 0.03125rem;

                    @media screen and (max-width: 500px) {
                        font-size: 0.625rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1rem;
                        letter-spacing: 0.025rem;
                    }
                }
            }

            .purchaseDate {
                >span:nth-child(1) {
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.25rem;
                    letter-spacing: 0.01563rem;
                    opacity: 0.5;

                    @media screen and (max-width: 500px) {
                        font-size: 0.625rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1rem;
                        letter-spacing: 0.025rem;
                    }

                }

                >span:nth-child(2) {
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5rem;
                    letter-spacing: 0.03125rem;
                    opacity: 0.5;

                    @media screen and (max-width: 500px) {
                        font-size: 0.75rem;
                    }
                }
            }

        }

        .assetInfo3 {
            .trackingNumber {
                >span:nth-child(1) {
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 24px;
                    letter-spacing: 0.1px;
                }

                >span:nth-child(2) {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: 0.18px;
                }
            }

            .trackingCompany {
                >span:nth-child(1) {
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 24px;
                    letter-spacing: 0.1px;
                }

                >span:nth-child(2) {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    letter-spacing: 0.18px;
                }
            }
        }
    }

    .actions {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 10px;
        width: 180px;
        max-width: 400px;
        column-gap: 10px;

        rainbow-button {
            height: 30px;
            color: white;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1rem;
            letter-spacing: 0.07813rem;
            text-transform: uppercase;
        }

        @media screen and (max-width: 900px) {
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            max-width: 100%;
            flex-wrap: wrap;
            padding-top: 15px;

            rainbow-button {
                width: 100%;
                font-size: v.pixelToRem(12);
            }
        }

        @media screen and (max-width: 500px) {
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            max-width: 100%;
            width: 330px;
            flex-wrap: wrap;
            padding-top: 15px;

            rainbow-button {
                width: 100%;
                font-size: v.pixelToRem(12);
            }
        }



    }
}