.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    max-width: 350px;


    .title {
        font-size: 20px;
        font-weight: 500;
    }

    .description {
        font-size: 16px;
    }

    .actions {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;

        rainbow-button {
            min-width: 150px;
        }
    }
}