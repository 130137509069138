.root {
  canvas {
    width: 100% !important;
    height: 100% !important;
  }

}

.noData {
  margin-top: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  height: 150px;
  color: #FFFFFF20;
  border: 1px solid #FFFFFF20;
}