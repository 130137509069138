@use './variables.scss';

.mobileHeaderRoot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // min-height: 60px;
    background-color: variables.$headerBarColor;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    z-index: 2;
    padding: 10px 0 5px 0;

    .nextButton,
    .mobileBackButton {
        min-width: 120px;
        text-transform: capitalize;
        display: flex;
        justify-content: center;
        column-gap: 8px;
        box-sizing: border-box;
        flex: 0 0 120px;
        padding: 3px;
        color: #EE8F00;
        letter-spacing: 1.0px;

        a {
            display: flex;
            column-gap: 10px;
            text-decoration: none;

            svg {
                width: 20px;
                height: 20px;

            }
        }
    }


    .currentStep {
        flex: 10%;
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        letter-spacing: 1.1px;
    }
}



@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}