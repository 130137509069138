 .selectedItems {
     padding: 16px;
     display: flex;
     column-gap: 8px;
     row-gap: 8px;
     flex-wrap: wrap;
     background-color: inherit;
     border-radius: inherit;

     .selectedChip {
         color: white !important;
         background-color: black;

         svg {
             path {
                 fill: white !important;
                 mix-blend-mode: difference !important;
             }
         }

         span {
             mix-blend-mode: exclusion !important;
         }

         &:nth-child(1n) {
             background-color: lightyellow;
         }

         &:nth-child(2n) {
             background-color: lightgreen;
         }

         &:nth-child(4n) {
             background-color: lightblue;
         }

         &:nth-child(5n) {
             background-color: lightsalmon;
         }

     }
 }