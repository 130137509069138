@use "../../styles/_transitions";
@use "../../styles/_variables";

.myAccount {
    // width: 60vw;
    width: 100%;
    max-width: 1000px;
    padding: 25px 0 0 125px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 20px;

    @media screen and (max-width: 800px) {
        padding: 25px 0 0 25px;
    }

    .names {
        display: flex;
        column-gap: 20px;

        .profilePic {
            display: inherit;
            padding: 2px;
            height: fit-content;
            border-radius: 5%;
            @include transitions.animateBorderColor();

            img {
                border-radius: 5%;
                object-fit: cover;
            }
        }

        .nameDetails {
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            .name {
                label {

                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5rem;
                    letter-spacing: 0.00938rem;
                    opacity: 0.5;
                }

                span {
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5rem;
                    letter-spacing: 0.03125rem;
                }
            }

            .email {
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5rem;
                letter-spacing: 0.03125rem;
                //color: #98FFF9;
            }
        }
    }
}