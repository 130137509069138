@use '../animations';
@use '../mixins';
@use '../variables';

.root {
    display: flex;
    position: relative;
    @include animations.shimmer();
    width: 100%;
    text-overflow: ellipsis;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    font-size: inherit;

    &.txt {
        max-height: 36px;
        min-width: 500px;
    }

    &.txtArea {
        max-height: 40px;
    }

    &.img {
        height: 100%;
        width: 100%;
        min-width: 250px;
        min-height: 250px;
        border: .5px solid rgba(255, 255, 255, 0.1);
        white-space: initial;


    }

    &.avatar {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: .5px solid rgba(255, 255, 255, 0.1);

    }

    &.shortTxt {
        max-height: 36px;
        max-width: 100%;
    }


    &.btn {
        display: inline;
        max-height: 36px;
        max-width: 500px;
        border: 1px solid #ffffff40;
        border-radius: 25px;
        user-select: none;
        text-align: center;
    }

    .imageGallery {
        width: 100%;
    }


}




@include mixins.maxWidth800() {
    .root {
        flex-direction: column;
        // width: auto;
    }
}