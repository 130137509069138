@use './variables.scss';
@use './mixins.scss';



.footer {
    margin-top: 100px;
}


.footermain {
    display: flex;
    flex: 1 0;
    height: 65px;
    justify-content: center;
    border-top: .05px solid white;
    width: 100%;
    z-index: 4;
    background-color: variables.$documentBackgroundColor;
    position: relative;

    bottom: 0px;
    padding-top: 10px;


    a {
        display: flex;
        align-items: center;
        padding: 5px;
        text-decoration: none;
        color: white;
        margin: 10px;
        font-size: .8rem;

    }

    a::after {
        content: " | ";
        margin: 5px;
    }


    @include mixins.maxWidth800() {
        display: none;
    }

}