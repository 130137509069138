@use './variables.scss';


.createListingType {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-color 500ms ease-in-out;
    min-height: 250px;


    a {
        img {
            min-height: 303px;

        }
    }

    &:hover,
    &.selected {
        transition: background-color 500ms ease-in-out;

        .label {
            cursor: pointer;
            box-shadow: 0px 2px 10px rgba(255, 255, 255, 0.25);

            .dot {
                background-color: variables.$documentBackgroundColor;
            }

            color: variables.$documentBackgroundColor;
            background-color: #FFFFFF;
        }
    }

    .label {
        width: 195px;
        height: 32px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        border-radius: 74px;
        column-gap: 10px;

        .text {
            justify-self: flex-start;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.4px;
        }

        .dot {
            position: absolute;
            left: 15px;
            width: 12px;
            height: 12px;
            border-radius: 25px;
            background-color: #FFFFFF;
        }
    }
}