.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #ffffff40 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #ffffff40 !important;
}

.MuiOutlinedInput-root {
  color: white !important;
  border-color: white !important;
}
.MuiOutlinedInput-adornedStart .MuiTypography-colorTextSecondary {
  color: #ffffff40 !important;
}

.MuiOutlinedInput-adornedStart:focus-within .MuiTypography-colorTextSecondary {
  color: white !important;
}

.MuiOutlinedInput-adornedStart input:not(:placeholder-shown),
.MuiOutlinedInput-adornedStart {
  color: white !important;
}

.MuiFormHelperText-root {
  color: #ffffff77 !important;
}

.MuiSlider-track {
  background-color: #98fff9 !important;
}
.MuiSlider-rail {
  background-color: #ffffff40 !important;
}

.MuiSlider-mark,
.MuiSlider-mark .MuiSlider-markActive {
  background-color: #ffffff40 !important;
}

.MuiSlider-thumb {
  background-color: var(--primary-color) !important;
  border: 2px solid #ffffff40 !important;
}

.MuiSlider-root {
  color: var(--primary-color) !important;
}
