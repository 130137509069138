@use "./variables.scss";
@use "./mixins.scss";
@use "./animations.scss";
@use "./transitions.scss";


.buttonContainer {
    display: flex;

    &.disabled {
        opacity: .3;
        pointer-events: none;

        .button {
            color: #ffffff40 !important;
            background: #ffffff40 !important;

            &.outlined {
                box-shadow: none;
                height: 40px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
        }
    }

    .button {
        display: block;
        text-align: center;
        padding: 2px;
        border-radius: 25px;

        &.dangerous {
            width: 100%;
            border: 1px solid red;
        }

        &.outlined {
            width: 100%;
            @include transitions.animateBorderColor();
        }

        &.secondary-outline {
            border: 1px solid #ffffff;
            background: variables.$documentBackgroundColor;
            box-shadow: none;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;


            &:hover {
                background: #ffffff40;
                border: 1px solid #98FFF9;
                color: #98FFF9;

            }
        }


        &.fullWidth {
            width: 100%;
        }

        button {
            all: unset;
            background: variables.$documentBackgroundColor;
            border-radius: 25px;
            width: 100%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 8px;
            height: 100%;


            &.fullWidth {
                width: 100%;
            }
        }


        a {

            all: unset;
            padding: 0 10px;
            background: variables.$documentBackgroundColor;
            border-radius: 25px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 8px;
            height: 40px;

            &.fullWidth {
                width: 100%;
            }
        }

    }
}