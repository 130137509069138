@use './variables.scss';

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hoverWithBorders {
  0% {
    background: unset;
    border-right: inherit;
  }

  50% {
    background: variables.$primaryColor;
    // color: white;
    border: 2px solid variables.$primaryColor;
  }

  100% {
    background: lighten($color: variables.$primaryColor, $amount: 20);
    color: white;
    border: 2px solid variables.$primaryColor;
    border-radius: 2px;
  }
}

@keyframes hoverWithBordersNoBackground {
  0% {
    background: unset;
    border-right: inherit;
  }

  50% {
    background: variables.$primaryColor;
    // color: white;
    border: 2px solid variables.$primaryColor;
  }

  100% {
    border: 2px solid variables.$primaryColor;
    border-radius: 2px;
  }
}


@mixin pulseBoxShadow($color1: rgba(0, 123, 255, 0.7), $color2: rgba(0, 123, 255, 0)) {
  animation: pulse-box-shadow 1s infinite;

  @keyframes pulse-box-shadow {
    0% {
      box-shadow: 0 0 0 0 $color1;
    }

    100% {
      box-shadow: 0 0 0 10px $color2;
    }
  }

}

@mixin rotatingBorder($degree: 180deg, $animation: forwards) {

  background-size: 100% 100%;
  background-size: 100% 100%;
  animation: 2s rotate4 $animation;
  animation-timing-function: linear;
  // background: conic-gradient(from var(--angle), transparent, #EE9002, transparent, #099ABA, transparent, #A000D8, transparent, #A000D8, transparent);
  background: conic-gradient(from var(--angle), transparent, transparent, rgb(0, 208, 255), transparent, transparent);

  &>* {
    background: #161D22;
  }

  @content ;

  @keyframes rotate4 {
    0% {
      box-shadow: 0px 0 8px 2px rgb(1, 39, 48);
      outline: 2px solid rgb(1, 68, 83);
    }

    50% {
      box-shadow: 0px 0 6px 2px rgb(2, 193, 236);
      outline: 2px solid rgb(0, 177, 216);
    }

    100% {
      --angle: 360deg;
      box-shadow: 0px 0 10px 2px rgb(1, 132, 161);
      outline: 2px solid rgb(1, 93, 114);
    }
  }

}



@mixin shimmer() {


  color: rgba(255, 255, 255, 0.1);
  background: -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
  background: -moz-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
  background: gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff));
  background: linear-gradient(to right, #222, #222, #fff, #222, #222);
  background-size: 80px 100%;
  background-clip: text;

  animation-name: shimmer;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #222;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  font-weight: 300;
  font-size: 1em;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0px;

}

@keyframes shimmer {
  0% {
    background-position: top left;
    color: linear(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  }


  100% {
    background-position: 120%;
    background-size: 80px 100%;
  }
}

@keyframes hover-skew-wobble {
  15% {
    transform: skew(-12deg);
  }

  30% {
    transform: skew(10deg);
  }

  50% {
    transform: skew(-6deg);
  }

  65% {
    transform: skew(4deg);
  }

  80% {
    transform: skew(-2deg);
  }

  100% {
    transform: skew(0);
  }
}

@keyframes rotatingBorder {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@keyframes pulse {
  0% {
    box-shadow: 0px 0 8px 2px rgb(1, 39, 48);
    outline: 2px solid rgb(1, 68, 83);
  }

  50% {
    box-shadow: 0px 0 10px 2px rgb(1, 132, 161);
    outline: 2px solid rgb(1, 93, 114);
  }

  100% {
    --angle: 360deg;
    box-shadow: 0px 0 6px 2px rgb(2, 193, 236);
    outline: 2px solid rgb(0, 177, 216);
  }
}