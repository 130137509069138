.title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    @media (max-width: 800px) {
        padding: 16px;
    }

    label {
        color: rgba(255, 255, 255, 0.25) !important;
    }

    .titleInput {
        max-width: 600px;
        width: 100%;
        margin-top: 16px;

        input {
            box-sizing: content-box !important;
        }
    }
}