@use "../styles/dialog.scss";
@use "../styles/mixins.scss";
@use "../styles/variables.scss";

.footer {
    position: fixed;
    bottom: 0;
    height: 66px;
    background: variables.$documentBackgroundColor;
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 30px;
    align-items: center;
    box-shadow: -1px -6px 13px 0px #0000006e;
    @include mixins.hideWhenGreaterThan800();
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    z-index: 2;

    a {

        // .notificationIcon {}

        .plusIcon {
            fill: #ffffff10;
        }

        svg {
            fill: #ffffffb3;
        }

        .avatar {
            border-radius: 50%;
            height: 30px;
            width: 30px;
            border: 2px solid #ffffff90;

        }

    }




}