@use './variables.scss';


.container {

    height: 100%;
    padding: 8px 8px 0 8px;

    .cardDescription {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #FFFFFF;
        max-width: 650px;

        max-width: 100%;
        background-color: variables.$documentBackgroundColor;
        color: white;
        padding: 15px;
        margin: 15px 0;
        border-radius: 4px;

        input {
            height: 70px;
        }
    }
}