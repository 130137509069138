@use "./mixins.scss";

@import url(https://fonts.googleapis.com/css?family=VT323);

@keyframes popup {
  100% {
    opacity: 1;
  }
}

@keyframes flashLastChar {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

}

.right-bar {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  min-width: 280px;
  position: relative;
  max-width: 280px;


  .image1 {
    position: relative;

    img {
      max-width: 185px;
      position: relative;
      border-radius: 20px;
      transition: opacity 2s ease-in-out;
      opacity: 80%;
    }

    .imgcontainer1 {
      position: relative;
      border-radius: 20px;
      width: 185px;
      height: 308px;
      overflow: hidden;
      border: 2px solid #053d33;
      background-color: transparent;
    }



    .hidden1 {
      position: absolute;
      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.60);
      font-size: 18px;
      font-family: 'VT323';
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
      opacity: 0;
      //transition-delay: 0.2s;
      transition-property: top;
      transition-timing-function: cubic-bezier(.52, .12, .1, 1.26);
      position: relative;
      //top: -100%;
      right: 100%;
      text-shadow:
        0 0 1px #16ffd4,
        0 0 2px #16ffd4,
        0 0 4px #086856,
        0 0 6px #086856;
    }

    .hidden2 {
      position: absolute;
      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.60);
      font-size: 18px;
      font-family: 'VT323';
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
      opacity: 0;
      //transition-delay: 0.2s;
      transition-property: top;
      transition-timing-function: cubic-bezier(.52, .12, .1, 1.26);
      position: relative;
      //top: -100%;
      right: 100%;
      text-shadow:
        0 0 1px #16ffd4,
        0 0 2px #16ffd4,
        0 0 4px #086856,
        0 0 6px #086856;
    }

    .hidden3 {
      position: absolute;
      font-size: 15px;
      font-style: normal;
      font-family: 'VT323';
      letter-spacing: 0.2px;
      font-weight: 300;
      line-height: 24px;
      margin-left: 10px;
      margin-right: 10px;
      top: 12%;
      opacity: 0;
    }

    .hidden4 {
      position: absolute;
      font-size: 15px;
      font-style: normal;
      font-family: 'VT323';
      letter-spacing: 0.2px;
      font-weight: 300;
      line-height: 24px;
      margin-left: 10px;
      margin-right: 10px;
      top: 17%;
      opacity: 0;
    }


    .hidden5 {
      position: absolute;
      font-size: 15px;
      font-style: normal;
      font-family: 'VT323';
      letter-spacing: 0.2px;
      font-weight: 300;
      line-height: 24px;
      margin-left: 10px;
      margin-right: 10px;
      top: 22%;
      opacity: 0;
    }

    .hidden6 {
      position: absolute;
      font-size: 15px;
      font-style: normal;
      font-family: 'VT323';
      letter-spacing: 0.2px;
      font-weight: 300;
      line-height: 24px;
      margin-left: 10px;
      margin-right: 10px;
      top: 27%;
      opacity: 0;
    }

    .hidden7 {
      position: absolute;
      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.60);
      font-size: 15px;
      font-family: 'VT323';
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
      opacity: 0;
      //transition-delay: 0.2s;
      transition-property: top;
      transition-timing-function: cubic-bezier(.52, .12, .1, 1.26);
      position: relative;
      //top: -100%;
      right: 100%;
      text-shadow:
        0 0 1px #16ffd4,
        0 0 2px #16ffd4,
        0 0 4px #086856,
        0 0 6px #086856;
    }

    span {

      position: absolute;
      transition: opacity 1s ease-in-out;
      top: 85%;
      left: 6%;
      font-family: 'VT323';
      font-size: 18px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;
      text-shadow:
        0 0 4px #16ffd4,
        0 0 8px #16ffd4,
        0 0 16px #086856,
        0 0 22px #086856;

    }





  }

  .image1:hover .imgcontainer1 {
    //transition: opacity 5s ease-in-out;
    transition: border-color 2s ease-in-out, box-shadow 2s ease-in-out;
    box-shadow:
      0 0 4px #16ffd4,
      0 0 8px #16ffd4,
      0 0 16px #086856,
      0 0 22px #086856;
    border: 2px solid #16ffd4;
    border-radius: 20px;
    background-color: #161D22;
  }



  .image1:hover img {
    opacity: 0;
  }

  .image1:hover span {
    opacity: 0;
  }

  .image1:hover .hidden1 {
    top: -105%;
    right: 0%;
    transition-delay: 1s;
    opacity: 1;
  }

  .image1:hover .hidden1:after {
    content: '.';
    position: absolute;
    animation-name: flashLastChar;
    animation-duration: 0.4s;
    animation-iteration-count: 7;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }


  .image1:hover .hidden2 {
    top: -111%;
    right: 0%;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 3.5s;
    animation-fill-mode: forwards;
  }

  .image1:hover .hidden2:after {
    content: '.';
    position: absolute;
    animation-name: flashLastChar;
    animation-duration: 0.4s;
    animation-iteration-count: 4;
    animation-delay: 3.5s;
    animation-fill-mode: forwards;
  }

  .image1:hover .hidden3 {
    //opacity: 1;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 5.5s;
    animation-fill-mode: forwards;
    //transition: all 2.5s ease-in-out;
  }

  .image1:hover .hidden3:after {
    content: 'found';
    opacity: 0;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 6.5s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }

  .image1:hover .hidden4 {
    //opacity: 1;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 7s;
    animation-fill-mode: forwards;
    //transition: all 2.5s ease-in-out;
  }

  .image1:hover .hidden4:after {
    content: 'found';
    opacity: 0;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 8.3s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }

  .image1:hover .hidden5 {
    //opacity: 1;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 9s;
    animation-fill-mode: forwards;
    //transition: all 2.5s ease-in-out;
  }

  .image1:hover .hidden5:after {
    content: 'found';
    opacity: 0;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 11s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }

  .image1:hover .hidden6 {
    //opacity: 1;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 12s;
    animation-fill-mode: forwards;
    //transition: all 2.5s ease-in-out;
  }

  .image1:hover .hidden7 {
    top: -50%;
    right: 0%;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 13s;
    animation-fill-mode: forwards;
  }

  .image1:hover .hidden7:after {
    content: " ONLINE";
    position: absolute;
    animation-name: flashLastChar;
    animation-duration: 1s;
    animation-iteration-count: 10;
    animation-delay: 13s;
    animation-fill-mode: forwards;
  }

  &:not(:hover) .imgcontainer {
    // Apply transition to non-hover state
    border-color: #053d33;
    transition: border-color 2s ease-in-out;
    box-shadow: none;
    background-color: transparent; // Return to the original state
  }



  .image2 {
    position: relative;


    img {
      max-width: 185px;
      position: relative;
      border-radius: 20px;
      transition: opacity 2s ease-in-out;
      opacity: 80%;
    }

    .imgcontainer2 {
      position: relative;
      border-radius: 20px;
      width: 185px;
      height: 308px;
      overflow: hidden;
      border: 2px solid #631b07;
      background-color: transparent;
    }



    .hidden8 {
      position: absolute;
      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.60);
      font-size: 18px;
      font-family: 'VT323';
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
      opacity: 0;
      //transition-delay: 0.2s;
      transition-property: top;
      transition-timing-function: cubic-bezier(.52, .12, .1, 1.26);
      position: relative;
      //top: -100%;
      right: 100%;
      text-shadow:
        0 0 1px #f5be19,
        0 0 2px #f5be19,
        0 0 4px #c7360e,
        0 0 6px #c7360e;
    }

    .hidden9 {
      position: absolute;
      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.60);
      font-size: 18px;
      font-family: 'VT323';
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
      opacity: 0;
      //transition-delay: 0.2s;
      transition-property: top;
      transition-timing-function: cubic-bezier(.52, .12, .1, 1.26);
      position: relative;
      //top: -100%;
      right: 100%;
      text-shadow:
        0 0 1px #f5be19,
        0 0 2px #f5be19,
        0 0 4px #c7360e,
        0 0 6px #c7360e;
    }

    .hidden10 {
      position: absolute;
      font-size: 15px;
      font-style: normal;
      font-family: 'VT323';
      letter-spacing: 0.2px;
      font-weight: 300;
      line-height: 24px;
      margin-left: 10px;
      margin-right: 10px;
      top: 12%;
      opacity: 0;
    }

    .hidden11 {
      position: absolute;
      font-size: 15px;
      font-style: normal;
      font-family: 'VT323';
      letter-spacing: 0.2px;
      font-weight: 300;
      line-height: 24px;
      margin-left: 10px;
      margin-right: 10px;
      top: 17%;
      opacity: 0;
    }


    .hidden12 {
      position: absolute;
      font-size: 15px;
      font-style: normal;
      font-family: 'VT323';
      letter-spacing: 0.2px;
      font-weight: 300;
      line-height: 24px;
      margin-left: 10px;
      margin-right: 10px;
      top: 22%;
      opacity: 0;
    }

    .hidden13 {
      position: absolute;
      font-size: 15px;
      font-style: normal;
      font-family: 'VT323';
      letter-spacing: 0.2px;
      font-weight: 300;
      line-height: 24px;
      margin-left: 10px;
      margin-right: 10px;
      top: 27%;
      opacity: 0;
    }

    .hidden14 {
      position: absolute;
      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.60);
      font-size: 18px;
      font-family: 'VT323';
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
      opacity: 0;
      //transition-delay: 0.2s;
      transition-property: top;
      transition-timing-function: cubic-bezier(.52, .12, .1, 1.26);
      position: relative;
      //top: -100%;
      right: 100%;
      text-shadow:
        0 0 1px #f5be19,
        0 0 2px #f5be19,
        0 0 4px #c7360e,
        0 0 6px #c7360e;
    }

    span {
      position: absolute;
      transition: opacity 1s ease-in-out;
      top: 85%;
      left: 6%;
      font-family: 'VT323';
      font-size: 18px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;
      text-shadow:
        0 0 4px #f5be19,
        0 0 8px #f8ca40,
        0 0 16px #c7360e,
        0 0 22px #c7360e;
    }
  }

  .image2:hover .imgcontainer2 {
    //transition: opacity 5s ease-in-out;
    transition: border-color 2s ease-in-out, box-shadow 2s ease-in-out;
    box-shadow:
      0 0 4px #f5be19,
      0 0 8px #f8ca40,
      0 0 16px #c7360e,
      0 0 22px #c7360e;
    border: 2px solid #f5be19;
    border-radius: 20px;
    background-color: #161D22;
  }

  .image2:hover img {
    opacity: 0;
  }

  .image2:hover span {
    opacity: 0;
  }

  .image2:hover .hidden8 {
    top: -105%;
    right: 0%;
    transition-delay: 1s;
    opacity: 1;
  }

  .image2:hover .hidden8:after {
    content: '.';
    position: absolute;
    animation-name: flashLastChar;
    animation-duration: 0.4s;
    animation-iteration-count: 7;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }

  .image2:hover .hidden9 {
    top: -111%;
    right: 0%;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 3.5s;
    animation-fill-mode: forwards;
  }

  .image2:hover .hidden9:after {
    content: '.';
    position: absolute;
    animation-name: flashLastChar;
    animation-duration: 0.4s;
    animation-iteration-count: 4;
    animation-delay: 3.5s;
    animation-fill-mode: forwards;
  }

  .image2:hover .hidden10 {
    //opacity: 1;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 5.5s;
    animation-fill-mode: forwards;
    //transition: all 2.5s ease-in-out;
  }

  .image2:hover .hidden10:after {
    content: 'underway';
    opacity: 0;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 6.5s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }

  .image2:hover .hidden11 {
    //opacity: 1;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 7s;
    animation-fill-mode: forwards;
    //transition: all 2.5s ease-in-out;
  }

  .image2:hover .hidden11:after {
    content: 'TBC';
    opacity: 0;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 8.3s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }

  .image2:hover .hidden12 {
    //opacity: 1;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 9s;
    animation-fill-mode: forwards;
    //transition: all 2.5s ease-in-out;
  }

  .image2:hover .hidden12:after {
    content: 'TBC';
    opacity: 0;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 11s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }

  .image2:hover .hidden13 {
    //opacity: 1;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 12s;
    animation-fill-mode: forwards;
    //transition: all 2.5s ease-in-out;
  }

  .image2:hover .hidden14 {
    top: -50%;
    right: 0%;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 13s;
    animation-fill-mode: forwards;
  }

  .image2:hover .hidden14:after {
    content: "  1.01";
    position: absolute;
    animation-name: flashLastChar;
    animation-duration: 1s;
    animation-iteration-count: 10;
    animation-delay: 13s;
    animation-fill-mode: forwards;
  }

  &:not(:hover) .imgcontainer2 {
    // Apply transition to non-hover state
    border-color: #631b07;
    transition: border-color 2s ease-in-out;
    box-shadow: none;
    background-color: transparent; // Return to the original state
  }

  .image3 {
    position: relative;

    img {
      max-width: 185px;
      position: relative;
      border-radius: 20px;
      transition: opacity 2s ease-in-out;
      opacity: 80%;
    }

    .imgcontainer3 {
      position: relative;
      border-radius: 20px;
      width: 185px;
      height: 308px;
      overflow: hidden;
      border: 2px solid #164f70;
      background-color: transparent;
    }



    .hidden15 {
      position: absolute;
      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.60);
      font-size: 18px;
      font-family: 'VT323';
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
      opacity: 0;
      //transition-delay: 0.2s;
      transition-property: top;
      transition-timing-function: cubic-bezier(.52, .12, .1, 1.26);
      position: relative;
      //top: -100%;
      right: 100%;
      text-shadow:
        0 0 1px #61bbf0,
        0 0 2px #61bbf0,
        0 0 4px #31aef7,
        0 0 6px #31aef7;
    }

    .hidden16 {
      position: absolute;
      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.60);
      font-size: 18px;
      font-family: 'VT323';
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
      opacity: 0;
      //transition-delay: 0.2s;
      transition-property: top;
      transition-timing-function: cubic-bezier(.52, .12, .1, 1.26);
      position: relative;
      //top: -100%;
      right: 100%;
      text-shadow:
        0 0 1px #61bbf0,
        0 0 2px #61bbf0,
        0 0 4px #31aef7,
        0 0 6px #31aef7;
    }

    .hidden17 {
      position: absolute;
      font-size: 15px;
      font-style: normal;
      font-family: 'VT323';
      letter-spacing: 0.2px;
      font-weight: 300;
      line-height: 24px;
      margin-left: 10px;
      margin-right: 10px;
      top: 12%;
      opacity: 0;
    }

    .hidden18 {
      position: absolute;
      font-size: 15px;
      font-style: normal;
      font-family: 'VT323';
      letter-spacing: 0.2px;
      font-weight: 300;
      line-height: 24px;
      margin-left: 10px;
      margin-right: 10px;
      top: 17%;
      opacity: 0;
    }


    .hidden19 {
      position: absolute;
      font-size: 15px;
      font-style: normal;
      font-family: 'VT323';
      letter-spacing: 0.2px;
      font-weight: 300;
      line-height: 24px;
      margin-left: 10px;
      margin-right: 10px;
      top: 22%;
      opacity: 0;
    }

    .hidden20 {
      position: absolute;
      font-size: 15px;
      font-style: normal;
      font-family: 'VT323';
      letter-spacing: 0.2px;
      font-weight: 300;
      line-height: 24px;
      margin-left: 10px;
      margin-right: 10px;
      top: 27%;
      opacity: 0;
    }

    .hidden21 {
      position: absolute;
      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.60);
      font-size: 18px;
      font-family: 'VT323';
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
      opacity: 0;
      //transition-delay: 0.2s;
      transition-property: top;
      transition-timing-function: cubic-bezier(.52, .12, .1, 1.26);
      position: relative;
      //top: -100%;
      right: 100%;
      text-shadow:
        0 0 1px #61bbf0,
        0 0 2px #61bbf0,
        0 0 4px #31aef7,
        0 0 6px #31aef7;
    }

    span {
      position: absolute;
      transition: opacity 1s ease-in-out;
      top: 85%;
      left: 3%;
      font-family: 'VT323';
      font-size: 18px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;
      text-shadow:
        0 0 4px #61bbf0,
        0 0 8px #61bbf0,
        0 0 16px #31aef7,
        0 0 22px #31aef7;
    }
  }

  .image3:hover .imgcontainer3 {
    //transition: opacity 5s ease-in-out;
    transition: border-color 2s ease-in-out, box-shadow 2s ease-in-out;
    box-shadow:
      0 0 4px #61bbf0,
      0 0 8px #61bbf0,
      0 0 16px #31aef7,
      0 0 22px #31aef7;
    border: 2px solid #61bbf0;
    border-radius: 20px;
    background-color: #161D22;
  }

  .image3:hover img {
    opacity: 0;
  }

  .image3:hover span {
    opacity: 0;
  }

  .image3:hover .hidden15 {
    top: -105%;
    right: 0%;
    transition-delay: 1s;
    opacity: 1;
  }

  .image3:hover .hidden15:after {
    content: '.';
    position: absolute;
    animation-name: flashLastChar;
    animation-duration: 0.4s;
    animation-iteration-count: 7;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }

  .image3:hover .hidden16 {
    top: -111%;
    right: 0%;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 3.5s;
    animation-fill-mode: forwards;
  }

  .image3:hover .hidden16:after {
    content: '.';
    position: absolute;
    animation-name: flashLastChar;
    animation-duration: 0.4s;
    animation-iteration-count: 4;
    animation-delay: 3.5s;
    animation-fill-mode: forwards;
  }

  .image3:hover .hidden17 {
    //opacity: 1;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 5.5s;
    animation-fill-mode: forwards;
    //transition: all 2.5s ease-in-out;
  }

  .image3:hover .hidden17:after {
    content: '   7.5%';
    opacity: 0;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 6.5s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }

  .image3:hover .hidden18 {
    //opacity: 1;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 7s;
    animation-fill-mode: forwards;
    //transition: all 2.5s ease-in-out;
  }

  .image3:hover .hidden18:after {
    content: '   $0.00';
    opacity: 0;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 8.3s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }

  .image3:hover .hidden19 {
    //opacity: 1;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 9s;
    animation-fill-mode: forwards;
    //transition: all 2.5s ease-in-out;
  }

  .image3:hover .hidden19:after {
    content: '   $0.00';
    opacity: 0;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 11s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }

  .image3:hover .hidden20 {
    //opacity: 1;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 12s;
    animation-fill-mode: forwards;
    //transition: all 2.5s ease-in-out;
  }

  .image3:hover .hidden20:after {
    content: '   0.00%';
    opacity: 0;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 11s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }

  .image3:hover .hidden21 {
    top: -50%;
    right: 0%;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 13s;
    animation-fill-mode: forwards;
  }

  .image3:hover .hidden21:after {
    content: "$0.00";
    position: absolute;
    animation-name: flashLastChar;
    animation-duration: 1s;
    animation-iteration-count: 10;
    animation-delay: 13s;
    animation-fill-mode: forwards;
  }

  &:not(:hover) .imgcontainer3 {
    // Apply transition to non-hover state
    border-color: #164f70;
    transition: border-color 2s ease-in-out;
    box-shadow: none;
    background-color: transparent; // Return to the original state
  }

  .image4 {
    position: relative;

    img {
      max-width: 185px;
      position: relative;
      border-radius: 20px;
      transition: opacity 2s ease-in-out;
      opacity: 80%;
    }

    .imgcontainer4 {
      position: relative;
      border-radius: 20px;
      width: 185px;
      height: 308px;
      overflow: hidden;
      border: 2px solid #5a3745;
      background-color: transparent;
    }



    .hidden22 {
      position: absolute;
      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.60);
      font-size: 18px;
      font-family: 'VT323';
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
      opacity: 0;
      //transition-delay: 0.2s;
      transition-property: top;
      transition-timing-function: cubic-bezier(.52, .12, .1, 1.26);
      position: relative;
      //top: -100%;
      right: 100%;
      text-shadow:
        0 0 1px #F6D7C3,
        0 0 2px #F6D7C3,
        0 0 4px #faa4c8,
        0 0 6px #faa4c8;
    }

    .hidden23 {
      position: absolute;
      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.60);
      font-size: 18px;
      font-family: 'VT323';
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
      opacity: 0;
      //transition-delay: 0.2s;
      transition-property: top;
      transition-timing-function: cubic-bezier(.52, .12, .1, 1.26);
      position: relative;
      //top: -100%;
      right: 100%;
      text-shadow:
        0 0 1px #F6D7C3,
        0 0 2px #F6D7C3,
        0 0 4px #faa4c8,
        0 0 6px #faa4c8;
    }

    .hidden24 {
      position: absolute;
      font-size: 15px;
      font-style: normal;
      font-family: 'VT323';
      letter-spacing: 0.2px;
      font-weight: 300;
      line-height: 24px;
      margin-left: 10px;
      margin-right: 10px;
      top: 12%;
      opacity: 0;
    }

    .hidden25 {
      position: absolute;
      text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.60);
      font-size: 18px;
      font-family: 'VT323';
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 10px;
      opacity: 0;
      //transition-delay: 0.2s;
      transition-property: top;
      transition-timing-function: cubic-bezier(.52, .12, .1, 1.26);
      position: relative;
      //top: -100%;
      right: 100%;
      text-shadow:
        0 0 1px #F6D7C3,
        0 0 2px #F6D7C3,
        0 0 4px #faa4c8,
        0 0 6px #faa4c8;
    }

    span {
      position: absolute;
      transition: opacity 1s ease-in-out;
      top: 85%;
      left: 6%;
      font-family: 'VT323';
      font-size: 18px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;
      text-shadow:
        0 0 4px #F6D7C3,
        0 0 8px #F6D7C3,
        0 0 16px #faa4c8,
        0 0 22px #faa4c8;
    }
  }

  .image4:hover .imgcontainer4 {
    //transition: opacity 5s ease-in-out;
    transition: border-color 2s ease-in-out, box-shadow 2s ease-in-out;
    box-shadow:
      0 0 4px #F6D7C3,
      0 0 8px #F6D7C3,
      0 0 16px #faa4c8,
      0 0 22px #faa4c8;
    border: 2px solid #F6D7C3;
    border-radius: 20px;
    background-color: #161D22;
  }

  .image4:hover img {
    opacity: 0;
  }

  .image4:hover span {
    opacity: 0;
  }

  .image4:hover .hidden22 {
    top: -105%;
    right: 0%;
    transition-delay: 1s;
    opacity: 1;
  }

  .image4:hover .hidden22:after {
    content: '!!!';
    position: absolute;
    animation-name: flashLastChar;
    animation-duration: 0.4s;
    animation-iteration-count: 7;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }


  .image4:hover .hidden23 {
    top: -111%;
    right: 0%;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 3.5s;
    animation-fill-mode: forwards;
  }

  .image4:hover .hidden23:after {
    content: 'POKE-153C';
    position: absolute;
    animation-name: flashLastChar;
    animation-duration: 0.4s;
    animation-iteration-count: 4;
    animation-delay: 3.5s;
    animation-fill-mode: forwards;
  }


  .image4:hover .hidden24 {
    //opacity: 1;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 7s;
    animation-fill-mode: forwards;
    //transition: all 2.5s ease-in-out;
  }

  .image4:hover .hidden24:after {
    content: 'fail';
    opacity: 0;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 8.3s;
    animation-fill-mode: forwards;
    /* Use a keyframe animation */
  }

  .image4:hover .hidden25 {
    top: -50%;
    right: 0%;
    animation-name: popup;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
    animation-delay: 10s;
    animation-fill-mode: forwards;
  }

  .image4:hover .hidden25:after {
    content: " CORRUPTED";
    position: absolute;
    animation-name: flashLastChar;
    animation-duration: 1s;
    animation-iteration-count: 10;
    animation-delay: 11.5s;
    animation-fill-mode: forwards;
  }



  &:not(:hover) .imgcontainer4 {
    // Apply transition to non-hover state
    border-color: #5a3745;
    transition: border-color 2s ease-in-out;
    box-shadow: none;
    background-color: transparent; // Return to the original state
  }

}




@media (max-height: 720px) {
  .right-bar {
    div:last-of-type {
      display: none;
    }
  }
}

@media (max-height: 720px) {
  .right-bar {
    display: none;
  }
}