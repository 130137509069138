@use '../styles/variables' as v;

.selectedPlace {
    padding: 0 5px;
    font-size: small;
    color: v.$accent;
}

.input {
    height: 35px !important;
    width: 300px !important;
    border: 2px solid #FFFFFF40 !important;
    border-radius: 10px !important;
    color: white !important;
    background-color: transparent !important;
    outline: none;
    padding-left: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.18px;

    &::placeholder {
        font-weight: 400;
        opacity: 0.25;
        color: #FFFFFF;
    }
}