.container {
    padding: 16px;
    // min-height: 100%;

    .radio {
        display: flex;
        flex-direction: row;

        label {
            min-width: 180px;

        }
    }
}