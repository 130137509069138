@use "./variables.scss";
@use "./animations.scss";

.textarea {
  border: 1px solid variables.$borderColor;
  font-family: "Roboto";
  height: 200px;
  width: 100%;

  /*   &:invalid {
    box-shadow: 0px 0px 10px 0px $validationError;
  } */

  &:focus-visible {
    outline: none;
    // animation: hoverWithBordersNoBackground 200ms normal forwards;
  }
}

.maxChar {
  float: right;
  color: #ffffff60;
  font-size: 1rem;
  display: flex;
  justify-content: flex-end;
}