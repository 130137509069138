@use "variables" as v;
@use "mixins.scss";
@use "animations.scss";
@use "transitions.scss";



@mixin text-clamp($width: 100%) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: $width;

}

@mixin shipping {
  font-weight: 400;
  font-size: v.pixelToRem(12);
  font-weight: 400;
  letter-spacing: 0.4px;
  color: #8A8E90;
}


@mixin name-title {
  font-weight: 700;
  line-height: 24px;
  font-size: v.pixelToRem(16);
  border-bottom: 1px solid #8A8E90;
  margin-bottom: 8px;
}

@mixin background {
  background-color: #252D32;
  border-radius: 5px;
  padding: 5px;
  min-height: 90px;
}

@mixin price {
  line-height: 1.5rem;
  font-weight: 500;
  font-size: v.pixelToRem(16);
  letter-spacing: 0.5px;
}

.search-item-container {
  //all: unset;
  border-radius: 5px;
  position: relative;
  min-width: 250px;
  max-width: 250px;
  max-height: 480px;
  max-width: 225px;
  min-height: 390px;
  background: #161D22 !important;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -1px 1px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: relative;
  padding: 1px;


  .load {
    @include transitions.animateBorder();
  }

  &:hover {

    @include animations.rotatingBorder();
  }

  .thumbnail {

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 274px;
    max-height: 274px;
    //background-color: #24313A;
    background-size: 300%;
    background-blend-mode: soft-light;
    background-repeat: no-repeat;
    background-position: center;
    border-style: solid;
    border-radius: 8px 8px 0 0;
    border-color: #161D22;
    border-width: 4px;


    .noPhoto {
      opacity: .3;

      img {
        max-width: 229px;
        object-fit: contain;

      }
    }


    overflow: hidden;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(15px);
      border-radius: 4px;
      overflow: hidden;
    }

    img {

      min-height: 274px;
      min-width: 248px;
      border-radius: 4px;
      object-fit: cover;
    }
  }


  .content {
    letter-spacing: 0.4px;
    width: 100%;
    border-radius: 0 0 5px 5px;

    .mixedBundleDetail {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 10px;

      .name {
        @include name-title();
        width: 100%;
        max-width: 100%;
        @include text-clamp();
        margin: 0 8px;
        margin-top: 5px;
      }

      .background {
        @include background();
        display: flex;
        flex-wrap: wrap;
        min-height: 118px;
        margin-top: 8px;

        .mixedBundleLabel {
          line-height: v.pixelToRem(24);
          font-weight: 400;
          font-size: v.pixelToRem(14);
          color: v.$accent;
        }

        .priceContent {
          width: 40%;
          text-align: right;

          .marketGrowth {
            @include shipping();
            flex-direction: column;
          }

          .shippingTypeContent {
            @include shipping();
            flex-direction: column;

          }

          .price {
            @include price();
          }
        }

        .tagContent {
          width: 60%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          overflow-y: hidden;
          overflow-x: hidden;
          max-height: 60px;
          min-height: 100px;
          // padding-top: 5px;

          .tagChip {
            display: flex;
            flex-wrap: wrap;
            gap: 4px;

            span {
              max-width: 50% !important;
              border-radius: 15px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              // height: 12px;
              padding: 0px 10px;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              text-align: center;
              font-family: Roboto;
              font-size: v.pixelToRem(12);
              font-style: normal;
              font-weight: 500;
              // line-height: 12px;
              letter-spacing: 0.1px;


              &::after {
                content: ' '
              }

              // line-height: 15px;

              &:nth-child(1n) {
                background-color: lightyellow;
                color: black;
              }

              &:nth-child(2n) {
                background-color: lightgreen;
                color: black;
              }

              &:nth-child(4n) {
                background-color: lightblue;
                color: black;
              }

              &:nth-child(5n) {
                background-color: lightsalmon;
                color: black;
              }
            }
          }
        }
      }


    }


    .detail {
      padding: 5px 10px;

      .setContent,
      .seriesContent,
      .shippingTypeContent,
      .conditionContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 21px;
      }

      .background {
        @include background();
      }


      .setContent {
        align-items: flex-start;

        .set {
          font-weight: 400;
          font-size: v.pixelToRem(12);
          letter-spacing: 0.4px;
          color: v.$accent;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 150px;

        }


        .shippingPrice {
          @include shipping()
        }

      }


      .marketGrowth {
        display: flex;
        flex-direction: column;
        color: #8A8E90;
        font-weight: 400;
        font-size: v.pixelToRem(12);
        // line-height: 21px;
        letter-spacing: 0.4px;
        justify-content: flex-end;
        align-items: flex-end;
      }

      .shippingTypeContent {
        color: #8A8E90;
        font-weight: 400;
        font-size: v.pixelToRem(12);
        line-height: 21px;
        letter-spacing: 0.4px;
        justify-content: space-between;
        align-items: flex-end;

      }


      .seriesContent {
        align-items: flex-start;

        .series {
          line-height: v.pixelToRem(24);
          font-weight: 400;
          font-size: v.pixelToRem(14);
          color: v.$accent;

        }

        .price {
          @include price();
        }
      }

      .name {
        @include name-title();
        @include text-clamp();
      }

      .profilePic {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        cursor: pointer;
      }


      .avatar {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        cursor: pointer;
      }


      .conditionContent {

        //font-size: .75rem;
        font-weight: 400;
        font-size: v.pixelToRem(12);
        margin-top: 10px;

      }

      .rarity {
        >div {
          display: flex;
          column-gap: 5px;
          stroke: white;
          stroke-width: 2px;
          font-size: v.pixelToRem(12);

          .rarityLabel {
            display: inline-block;
            width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: v.pixelToRem(12);
          }

        }
      }


    }

    .priceGroup {
      display: none;
      all: unset;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: 15px;
      row-gap: 8px;

      display: none;

      .price {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: rgba(0, 0, 0, 0.87);
      }

      .user {
        font-weight: 400;
        font-size: 8px;
        line-height: .875rem;
        letter-spacing: 0.4px;
        color: rgba(0, 0, 0, 0.4);
      }

    }

  }

}


@include mixins.maxWidth800() {
  .search-item-container {
    min-width: 175px;
    // max-height: 392px;
    width: 175px;
    font-size: smaller;

    .content {
      .detail {
        padding: 0 5px;
      }

      .mixedBundleDetail {
        align-items: flex-start;
        padding: 0 5px;
        margin: 0;

        .name {

          font-size: 0.875rem;
          font-weight: 700;
          line-height: 24px;
          text-transform: uppercase;
          // max-width: 100%;
          max-width: 168px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0px 0px;
          margin: 0;

        }

        .background {
          max-width: 168px;

          .mixedBundleLabel {
            line-height: v.pixelToRem(24);
            font-weight: 400;
            font-size: v.pixelToRem(14);
            color: v.$accent;
          }


          .tagContent {
            min-height: 75px;
            max-width: 95px;

            .tagChip {
              span {
                max-width: 50% !important;
              }
            }
          }
        }
      }
    }

    .thumbnail {
      max-height: 233px;
      min-height: unset;

      a {
        img {
          min-height: 266px;
          min-width: 233px;
          object-fit: cover;
        }
      }
    }
  }
}