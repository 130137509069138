@use './transitions.scss';
@use './variables.scss';
@use './mixins.scss';
@use './animations.scss';

.expandingButton {
    position: relative;
    display: inline-block;
    background: #161D22;
    //cursor: pointer;
    border: 0;
    vertical-align: middle;
    color: #fff;
    border-radius: 25px;
    height: 30px;
    width: 30px;
    //border: 2px solid white;
}


.expandingButton .textContainer {
    max-width: 30px;
    max-height: 30px;
    padding: 5px;
    column-gap: 5px;
    display: flex;
    align-items: center;
    position: absolute;
    vertical-align: top;

    border: 2px solid white;
    border-radius: 25px;
}


.plus {
    all: unset;
    width: 20px;
    height: 20px;
    margin-left: -2px;
    vertical-align: middle;
}