@use "./variables.scss";
@use "./transitions.scss";
@use "./mixins.scss";
@use "./animations.scss";

.placeAdSearchResultsContainer {
  display: flex;
  flex-direction: column;
  // max-height: 550px;
  height: calc(100% - 50px);
  overflow-y: scroll;
  background-color: #161D22;
  border-radius: 4px;
  @include mixins.scrollbar(whiteSmoke);

  @include mixins.maxWidth800() {
    margin-top: 50px;
  }

  row-gap: 0.8rem;

  .beforeSearch {
    padding: 20px;

    .recentSearch {
      ul {
        padding: 0 10px;
      }

      ul li {
        list-style-image: none;
        display: flex;
        align-items: center;
        font-style: italic;
        justify-content: space-between;

      }
    }

    .trySearches {
      ul li {
        list-style: none;

        &::before {
          content: '';
          display: inline-block;
          height: 25px;
          width: 35px;
          position: absolute;
          background-image: url('../assets/svg/search.svg');
          background-size: contain;
          background-repeat: no-repeat;
          margin-left: -35px;

        }
      }
    }
  }

  .cardContainer {
    display: flex;
    flex-direction: row;
    align-content: center;
    column-gap: 25px;
    border-radius: 25px;
    padding: 2px;


    .cardContainer2 {
      display: flex;
      column-gap: 20px;
      background-color: variables.$documentBackgroundColor;
      border-radius: 5px;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-left: 10px;
      padding-right: 2px;
      width: 100%;
      background-color: #161D22;

      @include mixins.maxWidth440() {
        border-bottom: 1px solid #ffffff20;
      }
    }

    //transition: all 0.5s linear;


    &:hover {
      //transform: scaleX(0.99)
      //transition: all 0.5s linear;
      border-radius: 5px;
      cursor: pointer;
      @include transitions.animateBorderColorNoGlow(180deg);

    }



    .rarityIcon {
      // @include mixins.hideWhenLessThan600();
      display: flex;
      align-items: center;
    }

    .meta {
      display: grid;
      grid-template-columns: 200px 200px;
      letter-spacing: 0.15px;
      line-height: 24px;
      font-size: .875rem;
      max-width: 350px;


      .title {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 24px;
        letter-spacing: 0.15px;
        width: 100%;
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
      }

      .number {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 600;
        letter-spacing: 0.15px;
        min-width: 100px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 20px;
      }

      .rarity {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: 0.15px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 20px;
      }

      .set {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: 0.15px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 20px;
      }

      .series {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: 0.15px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        column-gap: 20px;

      }


      @include mixins.maxWidth440() {
        width: 100%;
      }

      .number {
        font-weight: 700;
      }

      @media screen and (max-width: 440px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          font-size: 1rem;
        }

        .rarity,
        .series,
        .set,
        .number {
          font-size: 0.8rem;
        }

      }

    }

    .averageValueContainer {
      letter-spacing: 0.15px;
      line-height: 24px;
      align-self: center;
      font-size: .875rem;
      max-width: 300px;

      // @include mixins.hideWhenLessThan600();

      .averageValue {
        width: 100%;
      }

      .title {
        font-weight: 700;
        width: 100%;
      }
    }

    .image {
      display: flex;
      align-items: center;
      position: relative;
      height: 160px;

      img {

        height: 160px;
        border-radius: 5px;
      }
    }


    &.selected {
      //transform: scale(0.95);
      border-radius: 5px;
      cursor: pointer;
      @include transitions.animateBorderColor(180deg);
    }
  }


  .nextButton {
    display: flex;
    align-items: center;

    button {
      @include transitions.slowBackground(variables.$primaryColor, white);
      width: 128px;

      @include mixins.maxWidth440() {
        width: 100%;
      }


    }
  }

}