@use "./variables" as v;
@use "./mixins.scss";



.domesticPostageAmount,
.internationalPostageAmount {
  width: 300px;
}

.addressSearchContainer {
  margin: 15px 0;
}


@include mixins.maxWidth800() {
  .shipping {
    padding: 8px;
    height: 100%;

    :global .MuiFormGroup-root.MuiFormGroup-row {
      width: 100% !important;
      flex-wrap: nowrap !important;
    }

    :global .MuiFormControlLabel-root {
      font-size: v.pixelToRem(16);
      border: 1px solid #ffffff40;
      margin: unset;
      padding: 0 0px;
      height: 44px;
      justify-content: center;
      flex-grow: 1;
      flex-shrink: 1;
      white-space: nowrap !important;

      &:nth-child(1) {
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
      }

      &:nth-child(2) {
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
      }

      :global .PrivateRadioButtonIcon-root-5 {
        display: none;
      }

      :global .MuiIconButton-root.PrivateSwitchBase-root-1.MuiRadio-root.MuiRadio-colorSecondary.MuiIconButton-colorSecondary {
        display: none !important;
      }

      span {
        font-size: v.pixelToRem(14) !important;
      }

      &.selected {
        background: v.$accent !important;

        span {
          font-size: v.pixelToRem(14) !important;
          color: v.$documentBackgroundColor !important;
        }
      }
    }
  }
}