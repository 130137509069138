.galleryThumbs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 10px;
    column-gap: 10px;


    img {
        width: 80px;
    }
}

.imagePreview {
    position: relative;
    max-width: 150px;
    display: flex;
    column-gap: 10px;
    align-items: center;
    transition: all 0.2s ease-in-out;
    margin-bottom: .875rem;

    &:hover {

        .arrowRight::after,
        .arrowLeft::after {
            color: whitesmoke;
        }
    }


    .arrowLeft {
        left: 10px;
    }

    .arrowRight {
        right: 10px;
    }

    .arrowLeft,
    .arrowRight {
        box-sizing: border-box;
        position: absolute;
        display: block;
        transform: scale(var(--ggs, 1));
        width: 22px;
        height: 22px;
        border: 2px solid transparent;
        border-radius: 100px;
        cursor: pointer;


    }

    .arrowLeft::after,
    .arrowRight::after {
        content: "";
        color: transparent;
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 20px;
        height: 20px;
        border-bottom: 5px solid;
        border-left: 5px solid;



    }

    .arrowLeft::after {
        // left: 0px;
        transform: rotate(45deg);
    }

    .arrowRight::after {
        // right: 0px;
        transform: rotate(-135deg);

    }

    img {
        width: 150px
    }
}