@use "variables.scss";
@use "./animations.scss";
@use "./mixins";



.loadMoreContainer {
  min-width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.spinner {
  display: none;
  opacity: 0;
}

.searchResults {
  max-width: variables.$maxCenterWidth;
  column-gap: .875rem;
  row-gap: 8px;
  column-gap: 8px;


  @include mixins.minWidth(1300px) {
    min-width: 1050px;
  }

  img {
    &.shake {
      animation-name: shake;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }

  display: flex;
  // justify-content: left;
  // justify-content: space-around;
  flex-wrap: wrap;
  justify-content: center;
}

.noResultsFound {
  display: flex;
  justify-content: center;
  width: 80%;
  // height: 100%;
  max-width: variables.$maxCenterWidth;
  padding: 20px;
  border-bottom: 1px solid white;
  line-height: 1.4rem;
  // min-height: 84vh;
}

.loading {
  max-width: variables.$maxCenterWidth;
  width: 80%;
  height: 105px;
  padding: 20px;
  line-height: 1.4rem;

  svg {
    width: 231px;
    fill: blanchedalmond;
  }
}

@include mixins.maxWidth800() {
  .spinner {

    img {
      max-height: 80px;
      height: 100%
    }


    &.visible {
      position: fixed;
      top: 0;
      width: 100%;
      display: flex;
      margin-top: 70px;
      justify-content: center;
      align-items: center;
    }
  }

  .searchResults {
    margin: 30px 0 0 0;
    column-gap: 20px;
  }

}

@include mixins.maxWidth600() {

  .searchResults {
    margin: 30px 0 0 0;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
    background-color: variables.$documentBackgroundColor;
    padding-bottom: 6rem;
  }

}

@include mixins.maxWidth440() {
  .searchResults {
    margin: 20px 0 0 0;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 10px;
    background-color: variables.$documentBackgroundColor;
    padding-bottom: 6rem;
  }

}