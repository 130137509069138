@use 'variables';
@use 'mixins';

.headerBar {
  background: variables.$headerBarColor !important;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 44px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
  height: 60px;
  @include mixins.hideWhenLessThan800;

  .profilePic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
  }


  user-name {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    // width: 100%;
    cursor: pointer;

    // .name {
    //   @include mixins.scrollbar(900px);
    // }
  }

  .userName {
    cursor: pointer;

    .name {
      @include mixins.scrollbar(900px);
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 45px;

    svg {
      fill: #b1b1b1;

    }

    svg:hover .createicon {
      fill: white;
      transition: all 500ms ease-in-out;
    }

    svg:hover {
      fill: white;
      transition: all 500ms ease-in-out;

    }

    a :link {
      color: white;
    }

  }
}