@use 'variables';
@use 'mixins';

.payNow {

    padding: 10px 0;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    width: 100%;

    .payButtonContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 40px 0;
        row-gap: 16px;

        .paymentFailed {
            max-width: 600px;
        }

        .payButtons {
            width: 100%;

            max-width: 600px;

            >div {
                // min-height: 200px;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                padding: 16px 16px 0 16px;

                >div {
                    width: 100%;
                }
            }
        }
    }

    .feesAndCharges {
        display: flex;
        row-gap: 15px;

        .feesAndChargesRow {
            span {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                letter-spacing: 0.15px;
            }
        }

        .col {
            display: flex;
            flex: 1 0 50%;
            flex-direction: column;
            row-gap: 10px;
        }

        .col:nth-child(2) {
            text-align: right;
        }

    }


    @include mixins.maxWidth800() {
        padding: 20px;
        margin-top: 0;
    }


}