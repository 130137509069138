.messages {
    padding: 25px 0 0 125px;
    display: inline;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 600px;

    @media screen and (max-width: 800px) {
        padding: 25px 0 0 25px;
    }


    .thread {
        .title {
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            border: 1px solid dimgray;
            border-radius: 15px;
        }


        img {
            max-height: 60px;
        }

        .selectedMessage {
            display: flex;

            flex-direction: column;

        }
    }

    .inputTextContainer {
        display: flex;
        align-self: flex-end;
        width: 100%;

        margin-top: 30px;


        .inputText {
            width: 100%;
            // flex: 1
        }

        .inputButton {
            width: 100px;
            margin-left: 10px;
            // flex: 1
        }
    }

    .messagesContainer {
        margin-top: 35px;

        &.alignRight {
            text-align: right;
        }

        &.alignLeft {
            text-align: left;
        }

        .message {
            display: inline-block;
            position: relative;
            border: 1px solid #ccc;
            padding: 15px 15px 0 15px;
            max-width: 150px;
            font-size: 0.85rem;
            text-align: left;
            animation: pulse-box-shadow 1s infinite;

            &.me {
                background-color: lightgreen;

                div {
                    color: black;
                }
            }

            &.sender {
                background-color: lightblue;

                div {
                    color: black;
                }

            }

            label {
                position: absolute;
                right: 0;
                bottom: -25px;
                font-size: x-small;
                line-height: 30px;
            }

            .date {
                display: flex;
                font-size: x-small;
                flex-direction: column;
                justify-content: flex-end;
                bottom: 0;
                height: 35px;

            }

            border-radius: 15px;
        }
    }
}