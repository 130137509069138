 @use './variables';
 @use './mixins';

 .userName {
     display: flex;
     column-gap: 5px;
     align-items: center;
     //background-color: #252D32;
     border-radius: 5px;


     .rating {
         display: flex;
         color: #EE8F00;
         width: 50%;
         justify-content: flex-end;
         align-items: center;

         span {
             display: flex;
             font-size: 20px;
         }

         svg {
             font-size: 20px;
         }
     }

 }

 .user {
     font-size: 16px;
     line-height: 16px;
     letter-spacing: 0.4px;

 }

 .userrating {
     display: flex;
     margin-left: 57px;
     column-gap: 3px;
     justify-content: flex-end;
     align-items: center;
     margin-left: auto
 }

 .profilePic {
     border-radius: 50%;
     cursor: pointer;
     height: 24px;

     svg {
         width: 22px;
         height: 22px;
     }

     transition: all 0.3s ease;

     // :hover {
     //     height: 100px;
     //     z-index: 1;
     //     top: 0px;
     // }

 }


 .ratingstar {
     height: 22px;
     width: 22px;

     &.rated {
         fill: #EE8F00
     }

     &.unrated {
         stroke: #EE8F00;
         fill: transparent;
     }

 }