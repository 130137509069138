@use "../styles/variables";


.heading {
    display: block;

    width: 100%;
    height: 50px;
    // border-bottom: 1px solid #ccc;
    margin-bottom: 15px;

}

.ratings {
    flex: 1 0;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.user-rating-dialog {
    display: flex;
    padding: 10px 40px;
    flex-direction: column;


    .actionButtons {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        a {
            cursor: pointer;

            // &.cancelButton {

            // }

            &.postButton {
                color: variables.$accent
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.comment {
    display: flex;
    height: 100px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    width: 100%;
    max-width: 450px;
    background-color: variables.$documentBackgroundColor;
    color: white;
    padding: 15px;
    margin: 15px 0;
    border-radius: 4px;
}