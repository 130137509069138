@use './variables' as v;

.container {
    padding: 15px 0;
    max-width: 691px;

    @media screen and (max-width: 1000px) {
        max-width: 100vw;
        width: 100%;
    }

    @media screen and (max-width: 800px) {
        max-width: 100vw;
        // width: 98vw;
    }

    .priceSum {
        display: flex;
        position: relative;
        margin-top: 15px;
        margin-bottom: 15px;
        border-radius: 2.3125rem;
        background: #252D32;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        padding: 12px;
        justify-content: space-around;

        .noData {
            width: 100%;
            height: calc(100% - 20px);
            position: absolute;

            &::before {
                content: 'We havent got enough data';
                position: relative;
                display: flex;
                font-size: 1.1rem;
                width: 100%;
                justify-content: center;
                backdrop-filter: blur(2.4px);
                color: #ffffff90;
                background: linear-gradient(180deg, rgba(37, 45, 50, 0.5) 0%, rgba(37, 45, 50, 0.5) 100%);
            }


        }

        .hide {
            display: none;
        }

        .priceEst {
            display: flex;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.3rem;
            letter-spacing: 0.025rem;
            column-gap: 5px;
        }

        .priceInd {
            display: flex;
            font-size: 0.725rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem;
            letter-spacing: 0.025rem;
        }

    }
}

.currencyText {
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
    letter-spacing: 0.15px;
    color: rgba(113, 128, 112, 0.87);
    padding-left: 16px;
}