@use './mixins.scss';

section {
  &.invalid {
    border: 1px solid red;
    box-shadow: 0px 0px 10px red;
  }

  z-index:1;
}

.section {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  width: 100%;
  min-width: 701px;
  line-height: 24px;
  letter-spacing: 0.15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .titleClassName {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: #FFFFFF;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
  }

  .subTitleClassName {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.18px;
    color: #FFFFFF;
    padding-top: 10px;
  }
}


@include mixins.maxWidth1000() {
  .section {
    min-width: unset;
  }
}

@include mixins.maxWidth800() {
  .section {
    max-width: 800px;
    min-width: 80%;
    //margin-bottom: 15px;
  }

  .titleClassName {
    display: none;
  }

}